
import React from 'react';
import { Route, RouteComponentProps } from "react-router-dom";
import { ContentLayoutNoNav } from '../pageLayouts';


interface Props {
    Component: React.FC<RouteComponentProps>;
    path?: string;
    exact?: boolean;

}

const PageLayoutNoNavRoute = ({ Component, path, exact, ...rest }: Props) => {
    return (
        <Route path={path} exact={exact}  {...rest} render={(props: RouteComponentProps) => (
            <ContentLayoutNoNav>
                <Component {...props} />
            </ContentLayoutNoNav>
        )} />

    )
};


export default PageLayoutNoNavRoute;