import React, { useContext } from "react";
import styles from './../../ui.module.scss'
import { strings } from "../../localization/localization";
import { Toggle } from "@fluentui/react";
import { DarkModeContext, LanguageContext } from "../../providers";
import { useCookies } from "react-cookie/es6";


export  const UIToggelThemeButton = () => {
    const { darkMode, toggleDarkMode } = useContext(DarkModeContext);
    const { language } = useContext(LanguageContext);
    const [cookies, setCookie] = useCookies(['theme']);
    console.log(language);
    return (
        <Toggle
            className={styles.darkModeToggle}
            onText={strings.darkModeOn}
            offText={strings.darkModeOff}
            checked={darkMode}
            onChange={() => {
                if (!!toggleDarkMode) {
                    setCookie('theme', !darkMode?'dark':'light', { path: '/' });
                    toggleDarkMode();
                    
                }
                    
            }}
        />);
}