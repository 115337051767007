import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { ActionButton, Icon } from "@fluentui/react";
import React, { useContext } from "react";

import { loginRequest } from "../../authConfig";
import { LoggedInMenu } from "../../controls/LoggedInMenu";
import { strings } from "../../localization/localization";
import { LanguageContext } from "../../providers";
import styles from './../../ui.module.scss';

export const SignInSignOutButton = () => {

  
    const { instance } = useMsal();
    const { language } = useContext(LanguageContext);
    console.log(language);
    return (
        <>
            <AuthenticatedTemplate>
              <LoggedInMenu />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <ActionButton onClick={() => {
                  
                   instance.loginPopup(loginRequest);
                   // instance.loginRedirect();
                }
                }>
                    <span className={styles.signInText}>{strings.signIn}</span><Icon iconName="AddFriend" />
                </ActionButton>
            </UnauthenticatedTemplate>
        </>
    );
};
