import { IconButton, Link, Panel, PanelType, Stack, useTheme } from "@fluentui/react";
import React, { useState } from "react";
import { useContext } from "react";
import { LanguageButton } from "./../subComponents/LanguageButton";
import { SignInSignOutButton } from "./../subComponents/SignInSignOutButton";
import { UIToggelThemeButton } from "./../subComponents/UIToggelThemeButton";
import styles from './../../ui.module.scss';
import { strings } from "../../localization/localization";
import { globalConfig } from "../../globalconf";
import { footerlinks } from "./footerLinks";
import { LeftNavVisibilityContext } from "../../providers/useLeftNavigationVisibility";
import { NavPanel } from "./NavPanel";
import { useHistory } from "react-router-dom";

export type TopProps = {
    children?: React.ReactNode;
    logo: string;
    logoshort: string;
    logolink?: string;
    logotarget?: string;
};

export const TopBar: React.FC<TopProps> = ({ logo, logoshort, logolink,logotarget, children }) => {
    const theme = useTheme();
    const history = useHistory();
    const [showWaffel, setShowWaffel] = useState(false);
    const { isVisible } = useContext(LeftNavVisibilityContext);
    const [showNavPanel, setShowNavPanel] = useState(false);
    return (<Stack horizontal horizontalAlign="space-between"
        style={{
            backgroundColor: theme.palette.white,
            boxShadow: theme.effects.elevation8
        }}


        className={styles.TopBar} >
        <Stack horizontalAlign="start" horizontal>
            {globalConfig.features.showNavigation && isVisible && (
                <IconButton className="ms-hiddenLgUp" styles={{ root: { paddingRight: '15px', marginRight: '10px' } }}
                    iconProps={{ iconName: 'GlobalNavButton' }} onClick={() => setShowNavPanel(true)} />

            )}
            <Panel
                headerText="Loc Navigation"
                isOpen={isVisible && showNavPanel}
                onDismiss={() => setShowNavPanel(false)}
                type={PanelType.smallFixedNear}
                isLightDismiss

                closeButtonAriaLabel="loc Close"
            >
                <NavPanel
                    LinkActionExecuted={() => setShowNavPanel(false)}
                />
            </Panel>
            <span className={styles.topLogo}><a href={logolink ? logolink : '/'} target={logotarget ? logotarget : 'self'} onClick={(e) => {
                if (logolink?.indexOf('http') != -1) {
                    return true;
                }
                e.stopPropagation();
                e.preventDefault();
                history.push('/')
            }}>
                <img className="ms-hiddenSm" src={logo} alt={strings.logoAltText} />
                <img className="ms-hiddenMdUp" src={logoshort} alt={strings.logoAltText} />
            </a></span>
        </Stack>
        <Stack>{children}</Stack>
        <Stack horizontal wrap >
            <span className={styles.hideExtraSmale} ><UIToggelThemeButton /></span>
            <span style={{ paddingTop: '4px' }}><LanguageButton /></span>
            {globalConfig.features.enablelogin && (<span className={styles.hideExtraSmale}><SignInSignOutButton /></span>)}
            <span className={[styles.showExtraSmale, styles.waffelbtn].join(" ")}>
                <IconButton iconProps={{ iconName: 'Waffle' }}
                    onClick={() => { setShowWaffel(!showWaffel) }}
                />
            </span>
            <Panel
                headerText=""
                isOpen={showWaffel}
                onDismiss={() => { setShowWaffel(false) }}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel={strings.close}
            >
                <div>
                    <UIToggelThemeButton />
                    <SignInSignOutButton />
                </div>
                <hr></hr>
                {globalConfig.features.showFooterLinkWaffelExtraSmale && footerlinks.map((x, i) => {
                    return (<div key={'footerbtn' + i} className={styles.footerButton}><Link key={'footerblnk' + i} href={x.url} target={x.external ? '_blank' : '_self'} >{x.title}</Link></div>);
                })}

            </Panel>
        </Stack>

    </Stack >);
}