import { CommandBar } from "@fluentui/react";
import React from "react";
import cmsstyles from '../CMS.module.scss';

export interface ICMSCommandBarProps {
  EditClicked: () => void;
  SavedClicked: () => void;
  DiscardClicked: () => void;
  NewClicked: () => void;
  DeleteClicked: () => void;
  editMode: boolean;
  //Share handled Selfe
}
export const CMSCommandBar = (cmd: ICMSCommandBarProps) => {

  return (
    <div className={cmsstyles.commandBar} >


      <CommandBar
        items={[{
          key: 'newItem',
          text: 'New',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
          iconProps: { iconName: 'Add' },
          onClick: () => {
            cmd.NewClicked();
            alert('New TODO');
          }

        },
        {
          key: 'Nav',
          text: 'Nav Add/Remove',
          iconProps: { iconName: 'GlobalNavButtonActive' },
          onClick: () => alert('Nav TODO'),
        },
        {
          key: 'share',
          text: 'Share',
          iconProps: { iconName: 'Share' },
          onClick: () => alert('Share'),
          subMenuProps: {
            items: [
              {
                key: 'emailMessage',
                text: 'Email message',
                iconProps: { iconName: 'Mail' },
                onClick: () => alert('Share'),
              },
              {
                key: 'calendarEvent',
                text: 'Calendar event',
                iconProps: { iconName: 'Calendar' },
                onClick: () => alert('Share'),
              },
            ],
          },
        },
        {
          key: 'delete',
          text: 'Delete',
          iconProps: { iconName: 'RecycleBin' },
          onClick: () => { cmd.DeleteClicked(); },
        }
        ]}
        // overflowItems={_overflowItems}
        // overflowButtonProps={overflowProps}
        farItems={cmd.editMode ? [
          {
            key: 'tile',
            text: 'Save',


            iconProps: { iconName: 'Tiles' },
           
            subMenuProps: {
              items: [
                {
                  key: 'emailMessage',
                  text: 'Discard',
                  iconProps: { iconName: 'Mail' },
                  onClick: () => cmd.DiscardClicked(),
                },
                {
                  key: 'Save',
                  text: 'Save',
                  iconProps: { iconName: 'Mail' },
                  onClick: () => cmd.SavedClicked(),
                },
              ],
            },
          },

        ] :
          [{
            key: 'edit',
            text: 'Edit',


            iconProps: { iconName: 'Edit' },
            onClick: () => {
              console.log("click");

              cmd.EditClicked()
            }
          },

          ]}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>);
}