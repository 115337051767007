import React, { ReactNode } from "react";
import { ICMSBlock } from "../Services/CMSService.types";
import cmsstyles from '../CMS.module.scss';
import { CMSEditModePosition } from "../CMS.Types";
import { DirectionalHint, IconButton, Toggle } from "@fluentui/react";
import { CMSAddBlock } from "./CMSAddBlock";
import { CMSBlockTypes } from "./BlockTypes";


export interface ICMSBlockProps {
    data: ICMSBlock;

    index: number;
    last: boolean;
    first: boolean;
    editMode?: boolean;
    blockPositionChanged: (pos: CMSEditModePosition, index: number) => void;
    addBlock: (pos: CMSEditModePosition, data: ICMSBlock, index: number) => void;
    deleteBlock: (index: number) => void;
    updateBlock: (updatedData: ICMSBlock, index: number) => void;
}




export const CMSBlock = (block: ICMSBlockProps) => {


    const HeadLineTag = `h${block.data.headlineType}` as keyof JSX.IntrinsicElements;


    return (<>
        {block.editMode ?
            (<div className={cmsstyles.editBlockWrapper}>
                <div className={cmsstyles.actionBefore}>
                    <CMSAddBlock
                        iconName='BoxAdditionSolid'
                        directionalHint={DirectionalHint.bottomCenter}
                        added={(newData) => {
                            block.addBlock(CMSEditModePosition.before, newData, block.index);
                        }
                        }

                    />
                </div>
                <div className={cmsstyles.actionLayer}>
                    {!block.first && (<IconButton iconProps={{ iconName: 'Up' }} onClick={() => { block.blockPositionChanged(CMSEditModePosition.up, block.index); }} />)}
                    {!block.last && (<IconButton iconProps={{ iconName: 'Down' }} onClick={() => { block.blockPositionChanged(CMSEditModePosition.down, block.index); }} />)}
                    <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => { block.deleteBlock(block.index); }} />
                </div>
                {renderBlock()}
                <div className={cmsstyles.actionAfter}>
                    <CMSAddBlock
                        iconName='BoxAdditionSolid'
                        directionalHint={DirectionalHint.topCenter}
                        added={(newData) => {
                            block.addBlock(CMSEditModePosition.after, newData, block.index);
                        }}

                    />
                </div>
            </div>) :
            renderBlock()}
    </>
    );

    function renderBlock(): ReactNode {
        const blocktypes = CMSBlockTypes.filter(x => x.id === block.data.blockTypeId);
        let part = (<div></div>);
        if (blocktypes.length > 0) {
            part = React.createElement(blocktypes[0].renderer, {
                properties: block.data.properties,
                update: (newp:any) => updateBlockProperties(newp),
                editMode: block.editMode,
                index: block.index
            });
        }


        /*
             case CMSBlockType.Image:
                 const propsimage = block.data.properties as IBlockDataImage;
 
                 part = (
                     <>
                         {block.editMode && (<div className={cmsstyles.editProps}>
                             <input placeholder={'loc src'} key={'imgsrc' + block.index}
                                 onChange={(event) => { updateBlockProperties({ src: event.target.value }) }}
                                 value={propsimage?.src}></input>
                             <input placeholder={'loc alt'} key={'imgalt' + block.index}
                                 onChange={(event) => { updateBlockProperties({ alt: event.target.value }) }}
                                 value={propsimage?.alt}></input>
                         </div>)}
                         {  propsimage && propsimage.src && (<img src={propsimage.src} alt={propsimage?.alt} />)
                         }
                     </>);
 
 
                 break;
             case CMSBlockType.TOC:
                 const propstoc = block.data.properties as IBlockDataTOC;
                 part = (
                     <>
                         {block.editMode && (<div className={cmsstyles.editProps}>
                             <select placeholder={'loc maxLVL'} key={'max' + block.index}
                                 onChange={(event) => { updateBlockProperties({ maxLevel: event.target.value }) }}
                                 value={propstoc?.maxLevel}>
 
                                 </select>
                                 <Toggle onText={'showHiddenHeadlines'} offText={'hideHiddenHeadlines'} 
                                 checked={propstoc?.showHiddenHeadlines} onChange={()=> updateBlockProperties({ showHiddenHeadlines: !propstoc?.showHiddenHeadlines })} />
                                 
                         </div>)}
                         show toc ?JS berechnung oder Server API ?
                     </>);
                 
                 break;
 
         } */

        return (<div className={cmsstyles.block}>
            {block.editMode && <Toggle onText={'showHeadLine'} offText={'hideHeadline'} checked={block.data.showHeadline} onChange={() => updateBlock({ showHeadline: !block.data.showHeadline })} />}
            {block.data.showHeadline && (<div className={cmsstyles.blockHeadline}>

                <HeadLineTag>{block.editMode ?
                    <div>

                        <textarea placeholder={'loc Headline'} key={'headline' + block.index}
                            onChange={(event) => { updateBlock({ headline: event.target.value }) }}
                            value={block.data.headline}></textarea>
                    </div>
                    : block.data.headline
                }</HeadLineTag></div>)}
            <div className={cmsstyles.blockContent}  >
                {part}
            </div>
        </div>
        );
    }

    function updateBlock(changed: any): void {
        const resultblock = { ...block.data, ...changed }
        block.updateBlock(resultblock, block.index);
    }

    function updateBlockProperties(changed: any): void {
        const resultproperties = { ...block.data.properties, ...changed }
        const resultblock = block.data;
        resultblock.properties = resultproperties;
        block.updateBlock(resultblock, block.index);
    }
}



