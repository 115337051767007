import { MessageBar, MessageBarType, Stack } from "@fluentui/react";
import React from "react";
import { useContext } from "react";
import { IMessage, MessageType } from "../../interfaces/IMessages.type";
import { MessageContext } from "../../providers";

export const MessageArea: React.FC<{}> = () => {
    const { messages, removeMessage } = useContext(MessageContext);


    return (<Stack>
        {messages && messages.length > 0 && (
            messages.map((m: IMessage, i) => {
                return (
                    <MessageBar key={'MessageBar' + i}
                        messageBarType={getMessageBarType(m.messageType)}
                        onDismiss={() => { if (removeMessage) { removeMessage(i); } }}
                    >
                        {m.title}
                        {!!m.details && (<p>{m.details}</p>)}
                    </MessageBar>
                );
            })

        )}
    </Stack>);

}

export function getMessageBarType(mtyp: MessageType): MessageBarType {
    switch (mtyp) {

        case MessageType.Info:
            return MessageBarType.info
        case MessageType.Waring:
            return MessageBarType.warning
        default:
            return MessageBarType.error
    }
}