
import React, { useContext, useEffect, useRef, useState } from "react";
import { DefaultButton } from "@fluentui/react";

import { strings } from "../localization/localization";
import { LanguageContext } from "../providers";

type InstallAppButtonProps = {

};
export const InstallAppButton: React.FC<InstallAppButtonProps> = () => {

    const installPromptEventRef = useRef(undefined) as any;
    const { language } = useContext(LanguageContext);
    const [disabled, setDisabled] = useState(true);
    console.log(language);

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (event: any) => {

            console.log('beforeinstallprompt event was fired')

            // Prevent Chrome <= 67 from automatically showing the prompt
            event.preventDefault();

            // Stash the event so it can be triggered later.
            installPromptEventRef.current = event;

            // Update the install UI to notify the user app can be installed
            setDisabled(false);
        });
        return () => {
           // window.removeEventListener('beforeinstallprompt',)
            console.log("UNMOUNT",)
        }


    }, [language,installPromptEventRef])
    return (<DefaultButton text={strings.installApp} disabled={disabled} onClick={()=>{
        setDisabled(true);
        const installEvent= installPromptEventRef.current as any;
        installEvent.prompt();
        installEvent.userChoice.then((choice:any) => {
            if (choice.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }
            // Clear the saved prompt since it can't be used again
            installPromptEventRef.current = undefined;
          });
    }} />);
}