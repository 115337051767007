import { Callout, ChoiceGroup, DirectionalHint, IChoiceGroupOption, IconButton } from "@fluentui/react";
import React from "react";
import { useState } from "react";
import { CMSSectionType } from "../Services/CMSService.types";
import {  useId } from '@fluentui/react-hooks';


export interface ICMSAddOrUpdateSectionTypeProps {
    directionalHint?: DirectionalHint;
    iconName: string;
    changed: (sectionType: CMSSectionType) => void;
    selectedType?: CMSSectionType;
}

const options: IChoiceGroupOption[] = [
    {
        key: CMSSectionType.OneColumn.toString(),
        
        //imageSrc: TestImages.choiceGroupBarUnselected,
        imageAlt: 'Bar chart icon',
        //selectedImageSrc: TestImages.choiceGroupBarSelected,
        imageSize: { width: 32, height: 32 },
        text: 'OneColumn', // This text is long to show text wrapping.
    },
    {
        key: CMSSectionType.TwoColumn.toString(),
        imageSize: { width: 32, height: 32 },
        text: 'TwoColumn',
    },
    {
        key: CMSSectionType.TwoColumnLeft.toString(),
        imageSize: { width: 32, height: 32 },
        text: 'TwoColumnLeft',
    },
    {
        key: CMSSectionType.TwoColumnRight.toString(),
        imageSize: { width: 32, height: 32 },
        text: 'TwoColumnRight',
    },
    {
        key: CMSSectionType.ThreeColumn.toString(),
        imageSize: { width: 32, height: 32 },
        text: 'ThreeColumn',
    },
];

export const CMSAddOrUpdateSectionType = (props: ICMSAddOrUpdateSectionTypeProps) => {
    const [isCalloutVisible, setIsCalloutVisible] = useState(false);
    const buttonId = useId('callout-section-button');
    return (
        <div>
            <IconButton
            id={buttonId}
            iconProps={{ iconName: props.iconName }}
                onClick={() => {  setIsCalloutVisible(!isCalloutVisible) }}
            />
            {isCalloutVisible ? (
                <Callout
                    gapSpace={0}
                     target={`#${buttonId}`}

                    onDismiss={() => {setIsCalloutVisible(false);}}
                    directionalHint={props.directionalHint}
                    setInitialFocus
                >
                    <ChoiceGroup label="Pick one Sectiontyp"
                        defaultSelectedKey={props.selectedType?.toString()}
                        options={options}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                            if(option){
                                setIsCalloutVisible(false);
                                props.changed(+option.key);
                            }
                            
                        }}
                    />;
                </Callout>
            ) : null
            }
        </div>);
}