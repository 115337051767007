
import { PrimaryButton } from '@fluentui/react';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { DarkModeContext } from '../providers';
import styles from './PageNotFound.module.scss';


export interface IPageNotFoundProps { }


export const PageNotFound = () => {
  const { darkMode } = useContext(DarkModeContext);
  const history = useHistory();
  return (
    <div style={{padding:'15px'}}>
    <div className={styles.wrapper_404}>
 
        <div className={styles.four_zero_four_bg} style={{ mixBlendMode: darkMode ? "luminosity" : "multiply" }}>
          <h1 className={styles.textcenter}>404</h1>
        </div>

        <div className={styles.contant_box_404}>
          <h2>Look like you're lost</h2>
          <p>the page you are looking for not avaible!</p>
          <PrimaryButton text={"loc go Home"} onClick={() => { history.push('/') }} />
        </div>
 
    </div>

    </div>

  );

}
