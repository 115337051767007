import { useAccount, useMsal } from "@azure/msal-react";
import { ActionButton, Callout, Facepile, Icon } from "@fluentui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { loginRequest } from "../authConfig";
import { callMsGraph, ProfileData } from "../Services/graph";
import { useDidMount } from "../providers/useDidMount"
import { strings } from "../localization/localization";


export const LoggedInMenu = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [graphData, setGraphData] = useState(null);
    const [isCalloutVisible, setCalloutVisibility] = useState(false);
    const didMount = useDidMount();

    function RequestProfileData() {
        if (account) {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: account
            }).then((response) => {
                callMsGraph(response.accessToken).then(response => setGraphData(response));
            });
        };
    }

    useEffect(() => {

        if (didMount) {
           RequestProfileData()
        }
    });


    return (
        <>
            <ActionButton className={'loggedIn'}
                onClick={() => { setCalloutVisibility(!isCalloutVisible) }}  >
                <span>{account ? account.name : ""}</span>

                {account ? <span style={{ paddingLeft: '10px' }}>
                    <Facepile personas={[{
                        imageInitials: account.name?.split(' ').map((part) => part[0]).join('')
                        //imageUrl
                    }]} />
                </span> : null}


            </ActionButton>
            {isCalloutVisible ? (
                <Callout

                    gapSpace={0}
                    target={`.loggedIn`}
                    isBeakVisible={false}
                    beakWidth={0}
                    onDismiss={() => setCalloutVisibility(false)}

                >
                    <div style={{ padding: '0px 10px 20px 10px', display: 'table' }}>
                        <div style={{ display: 'table-row' }}>
                            <div style={{ display: 'table-cell' }}>
                            </div>
                            <div style={{ display: 'table-cell' }}>
                                <ActionButton onClick={() => instance.logoutRedirect()}  >
                                    <span style={{ paddingRight: '10px' }} >{strings.signOut}</span><Icon iconName="Leave" />
                                </ActionButton>
                            </div>
                        </div>
                        <div style={{ display: 'table-row' }}>
                            {graphData && (<ProfileData graphData={graphData} />)}
                        </div>
                    </div>

                </Callout>
            ) : null}
        </>
    );
};

