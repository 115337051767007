import * as React from 'react';
import { IDeviceStatusProps } from '../DeviceStatus';
import { IDeviceData, IDeviceDecoder, ITelemetry, TelemetryType } from '../DeviceStatus.type';
import { TelemetryBoxRenderer } from '../TelemetryBoxRenderer/TelemetryBoxRenderer';
import { IDeviceRenderProps } from './DeviceRender.type';
import styles from './GenericDevice.module.scss';



export interface IGenericDeviceState {
}

export default class GenericDevice extends React.Component<IDeviceRenderProps, IGenericDeviceState> {

    constructor(props: IDeviceRenderProps) {
        super(props);

        this.state = {

        };
    }


    public render(): React.ReactElement<IDeviceRenderProps> {
        if (!this.props.data) {
            return (<div></div>);
        }
     
        return (<div className={styles.telemetryView}>
            <div className={styles.genericTelemetry}>
                {

                    this.props.data.telemetry.map((x, i) => {
                        return TelemetryBoxRenderer(x,this.props.deviceStatus.darkMode, true,i);
                    })

                }
            </div>
        </div>);

    }
    
}


