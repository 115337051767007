import { defaultColorSchema, ITelemetryTypeDefinition, TelemetryType } from "./DeviceStatus.type";

export const TelemetryTypeDefinitions: ITelemetryTypeDefinition[] =
    [

        {
            telemetryType: TelemetryType.co2,
            iconPart: 'co2',
            lvlColorSchema: defaultColorSchema
        },
        {
            telemetryType: TelemetryType.temperature,
            iconPart: 'temperature',

        },
        {
            telemetryType: TelemetryType.humidity,
            iconPart: 'humidity',

        },
        {
            telemetryType: TelemetryType.pressure,
            iconPart: 'pressure',

        },
        {
            telemetryType: TelemetryType.particulate1,
            iconPart: 'pm1',
            //air-freshener-solid_white

        },
        {
            telemetryType: TelemetryType.particulate25,
            iconPart: 'pm25',
            //air-freshener-solid_white

        },
        {
            telemetryType: TelemetryType.particulate10,
            iconPart: 'pm10',
            //air-freshener-solid_white

        },
       
        
    ]

export const UnkownTelemetryType: ITelemetryTypeDefinition = {

    telemetryType: TelemetryType.unkown,
    iconPart: 'ruler-solid',


}