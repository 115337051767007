import React from "react";
import { graphConfig } from "../authConfig";

export async function callMsGraph(accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}



export const ProfileData = (props: any) => {
    console.log(props.graphData)
    return (
        <>
            <ProfileRow {...{ name: 'Title', value: props.graphData.jobTitle }} />
            <ProfileRow {...{ name: 'Mail', value: props.graphData.mail }} />
            <ProfileRow {...{ name: 'Phone', value: props.graphData.businessPhones[0] }} />
            <ProfileRow {...{ name: 'Location', value: props.graphData.officeLocation }} />
        </>
    );
};

export const ProfileRow = (props: any) => {
    return (
        <div style={{ display: 'table-row' }}>
            <div style={{ display: 'table-cell', padding:'0px 10px' }}>
                {props.name}
            </div>
            <div style={{ display: 'table-cell', padding:'0px 10px' }}>
                {props.value}
            </div>
        </div>
    );
};
