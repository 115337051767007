import React,{ createContext, useState } from "react";
import { strings } from "../localization/localization";
import { IProviderProps } from "./interfaces";

export interface ILanguageProvider {
    language: string;
    setLanguage?: (lang: string) => void;
}

export const LanguageContext = createContext<ILanguageProvider>({
    language: strings.getLanguage()
});



export const LanguageProvider = (props: IProviderProps) => {
    // Use State to keep the values

    const [language, applyLanguage] = useState(strings.getLanguage());


    const setLanguage = (lang: string) => {
        console.log('setLanguage triggerd');
        applyLanguage(lang);
        strings.setLanguage(lang);
    };

    // Make the context object:
    const userContext = {
        language: language,
        setLanguage: setLanguage,
    };

    // pass the value in provider and return
    return <LanguageContext.Provider value={userContext}>{props.children}</LanguageContext.Provider>;
};

