//import { useAccount, useMsal } from "@azure/msal-react";
import React, {  useEffect, useState } from "react";

//import { MessageContext } from "../providers";



export const Devices = () => {
   /* const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {}); */
    const [devices] = useState(null);
   /* const [loaded, setLoaded] = useState(false);

    const  addMessage  = useContext(MessageContext).addMessage;
  */

  
    useEffect(() => {

     /*   function RequestDevices() {
            if (account) {
                
                instance.acquireTokenSilent({
                    ...loginRequest,
                    account: account
                }).then((response) => {
                    const srv = new IOTCentralService(response.accessToken);
                   if (addMessage)
                               addMessage({
                                    messageType: MessageType.Alert,
                                    title: 'Oarsch',
                                    details: 'Bussi'
                                }); 
                 srv.callIOTCentralEndPoint('Devices').then((data) => {
                        setLoaded(true);
                       if (!!data.error) {
                             if (addMessage)
                               addMessage({
                                    messageType: MessageType.Alert,
                                    title: data.error.code,
                                    details: data.error.message
                                }); 
                        } else {
                            setDevices(data)
                        }
                       
                    }
                    ).catch((error) => {
                        if (addMessage) { debugger; addMessage(error); }
                        setLoaded(true);
                    });
                    //callIOTCentralDevices(response.accessToken).then(response => setDevices(response));
                });
            };
        }


        if (!loaded) {
            console.log("had update");
            RequestDevices()
           
        }
        return () => {
            console.log('unmout');
          };
*/
    });



    return (
        <div>
            {devices ? "Loaded" : "Loading"}
        </div>
    );
};

