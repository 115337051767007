import { DeviceDecoderType } from "./DeviceDecoders";
import { IDeviceStatusProps } from "./DeviceStatus";


export enum TelemetryType {
    unkown = 0,
    co2 = 1,
    temperature = 2,
    humidity = 3,
    particulate1=4,
    particulate25=5,
    particulate10=6,
    pressure=7,
}




export interface IDeviceDecoder {
    mainType: TelemetryType;
    label?: { de: string, en: string };
    iconpart: string;
    renderer: (deviceStatusProps:IDeviceStatusProps,devicedata?: IDeviceData) => JSX.Element;

}

export interface IDeviceDecoders {
    [key: string]: IDeviceDecoder;
}


export interface ITelemetryTypeDefinition {
    
        telemetryType:TelemetryType,
        iconPart: string,
        lvlColorSchema?:ILevelColorSchema
    
}
export interface ILevelColorSchema {
    lvl1:IColorSchema;
    lvl2:IColorSchema;
    lvl3:IColorSchema;
}

export interface IColorSchema {
     light: string, dark: string 
}

export const defaultColorSchema:ILevelColorSchema = {
    lvl1: { light: '#90ee90', dark: '#008000' },
    lvl2: { light: '#f3e762', dark: '#bdaa25' },
    lvl3: { light: '#c83c3c', dark: '#a10000' },
}

export interface ITelemetry {
    telemetryType: TelemetryType;
    telemetryDesc: string;
    telemetryValue: number;
    telemetryLogDate: Date;
    telemetryColor?: string;
    telemetryThreshold1?: number,
    telemetryThreshold2?: number,
    telemetryMaximum?: number,
    telemetryMeasurement?: string;
}

export interface IDeviceDataResponse {
    deviceCode: string;
    date: Date;
    description: string;
    deviceDecoder: DeviceDecoderType;
    applicationURL: string;
    telemetry: ITelemetry[];
}

export interface IDeviceData extends IDeviceDataResponse {
    color?: string;
}
