import React, { useContext, useEffect } from "react";



//import { strings } from "../localization/localization";

import { LeftNavVisibilityContext } from "../providers/useLeftNavigationVisibility";
import { useDidMount } from "../providers";


export const ContentLayoutNoNav = (props: any) => {
    const { isVisible,setVisibilety } = useContext(LeftNavVisibilityContext);
    const didMount = useDidMount();
    useEffect(() => {
        if (didMount) {
            if (isVisible && setVisibilety ) {
                setVisibilety(false);
            }
        }

    } );
    return (
        <>
            {props.children}
        </>
    );
};


