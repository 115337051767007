import { Dropdown, Link, Stack, useTheme } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { InstallAppButton } from '../../controls/InstallAppButton';
import { globalConfig } from '../../globalconf';
import { strings } from '../../localization/localization';
import {  LanguageContext } from '../../providers';
import styles from '../../ui.module.scss';
import { footerlinks } from './footerLinks';

type FooterProps = {
    children?: React.ReactNode;
};
export const FooterBar: React.FC<FooterProps> = ({ children }) => {

    const theme = useTheme();
    const { language } = useContext(LanguageContext);
    const history = useHistory();
    console.log(language);

    useEffect(() => {
        //rerender
    }, [language])
    const needDropdown: boolean =
        globalConfig.features.showFooterLinkDropdownSmall ||
        globalConfig.features.showFooterLinkDropdownExtraSmall;
    const dropdownclass:string[]= [];
    if(dropdownclass){
    if (globalConfig.features.showFooterLinkDropdownSmall) {
        dropdownclass.push('ms-hiddenMdUp');
    } else {
        dropdownclass.push('ms-hiddenSm');
    }
        
    if (globalConfig.features.showFooterLinkDropdownExtraSmall) {
        dropdownclass.push(styles.showExtraSmale);
    } else {
        dropdownclass.push(styles.hideExtraSmale);
    }
    
    }


    return (<Stack horizontal horizontalAlign="space-between" className={styles.FooterBar}
        style={{
            backgroundColor: theme.palette.white,
            boxShadow: theme.effects.elevation8
        }}
    >
        <Stack horizontal wrap >
            <span className="ms-hiddenSm">{strings.footerCopyrightTextLong}</span>
            <span className="ms-hiddenMdUp">{strings.footerCopyrightTextShort}</span>
        </Stack>

        <Stack>
            {globalConfig.features.showinstall && (<InstallAppButton />)}
            {children}</Stack>
        <Stack horizontal wrap >
            <div className="ms-hiddenSm">
                {footerlinks.map((x, i) => {
                    return (<span key={'footerbtn' + i} className={styles.footerButton}><Link key={'footerblnk' + i} href={x.url} target={x.external ? '_blank' : '_self'} >{x.title}</Link></span>);
                })}
            </div>
            {needDropdown && (
                <span className={dropdownclass.join(' ')}>
                    <Dropdown options={footerlinks.map((x, i) => { return ({ key: i, text: x.title }); })}
                        placeholder={strings.footerInfoShort} selectedKey={-1}
                        onChange={(e, option) => {
                            if (option) {
                                const footerlink = footerlinks[option.key as number];
                                if (footerlink.external) {
                                    window.open(footerlink.url, "_blank");
                                } else {
                                    history.push(footerlink.url);
                                }
                            }

                        }} />
                </span>
            )}

        </Stack>

    </Stack >);
}
