export const globalConfig = {

    auth: {
        clientId: "3fdf0e20-4184-4faa-9b6a-00a8a5629d9d"
    },
    webPush: {
        API_URL: "Https://iot.cubido.eu",
        VAPID_PUBLIC_KEY: "BJe_YhRjcxFmsyleLYDOAnoW2VdqcJ4Cc4grtupxIOex0QJNrhveVEJcEt_0VRnrxIkDHCsN11V1TIR-oKn2U0k"
    },
    features: {
        enablelogin: false,
        enablewebpush: false,
        showinstall: false,
        showFooterLinkDropdownSmall: true,
        showFooterLinkDropdownExtraSmall: false,
        showFooterLinkWaffelExtraSmale: true,
        showNavigation: false,
    },
    logourl:'https://www.cubido.at/services/iot/iot/co2messung',
    logoimgsmall:{light:'/cubido_logo_lightMode32.png',dark:'/cubido_logo_darkMode32.png'},
    logoimg:{light:'/cubido_Logo_lightMode.png',dark:'/cubido_Logo_darkMode.png'},
    logotarget:'_blank',
    staticNav: [
        {
            slug: '/',
            title: "Home",
            navItems: [
                {
                    slug: '/test1',
                    title: "Test1",
                },
                {
                    slug: '/test2',
                    title: "Test2",
                },
                {
                    slug: '/test3',
                    title: "Test3",
                },
                {
                    slug: '/test4',
                    title: "Test4",
                }
            ]
        },
        {
            slug: '/Register',
            title: "Register"
        },
        {
            slug: '/iiot',
            title: "IIOTC",
            navItems: [
                {
                    slug: '/iiot/devices',
                    title: "Devices",
                }
            ]
        }
    ]
}