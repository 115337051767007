import { IconButton, INavLink, INavLinkGroup, Nav } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ICMSNavItem } from "../../CMS/Services/CMSService.types";
import { useCMSNavigation } from "../../CMS/Services/useCMS";
import { globalConfig } from "../../globalconf";

import styles from './../../ui.module.scss';


export type NavPanels = {
    AllowMinimize?: boolean;
    CustomLinkAction?: (item: ICMSNavItem) => void;
    LinkActionExecuted?: () => void;
};

export const NavPanel: React.FC<NavPanels> = (props: NavPanels) => {

    const history = useHistory();
    const { data, loading } = useCMSNavigation();
    const [nav, setNav] = useState<INavLinkGroup[]>([])
    const [isSmale, setIsSmale] = useState(false);

    useEffect(() => {
        function MapNav(nodes: ICMSNavItem[]): INavLink[] {

            return nodes.map(node => ({
                name: node.title, url: node.slug,
                key: node.slug,
                isExpanded: true,
                onClick: (e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    if (props.CustomLinkAction) {
                        props.CustomLinkAction(node);
                    } else {
                        history.push(node.slug);
                        if (props.LinkActionExecuted) {
                            props.LinkActionExecuted();
                        }
                    }

                },
                links: node.navItems ? MapNav(node.navItems) : undefined
            }));
        }
        let glinks = MapNav(globalConfig.staticNav as any);

        if (!loading && data && data.navItems && data.navItems.length > 0) {
            glinks = glinks.concat(MapNav(data.navItems))
        }
        const navstruct: INavLinkGroup[] = [
            {
                //collapseByDefault: true,
                links: glinks
            }];
        setNav(navstruct as any);
    }, [data, loading,history,props]);

    return (<div className={styles.NavPanel}>

        <Nav initialSelectedKey={history.location.pathname} groups={nav} styles={{ root: { zoom: isSmale ? 0.6 : 1 } }} />
        {props.AllowMinimize && (<div className={styles.NavSiceToggle}>
            <IconButton iconProps={{ iconName: isSmale ? 'DoubleChevronRight' : 'DoubleChevronLeft' }} onClick={() => { setIsSmale(!isSmale) }} />
        </div>)}
    </div>);
}