

import React,{ useContext } from 'react';

import {  useParams } from 'react-router-dom';
import DeviceStatus from '../controls/DeviceStatus/DeviceStatus';

import { DarkModeContext, LanguageContext } from '../providers';

//import styles from './SensorInformation.module.scss';



export const SensorInformation = () => {
    const  {sensorId,key}  = useParams() as any;
    const { darkMode } = useContext(DarkModeContext);
    const { language } = useContext(LanguageContext);
    
    const deviceStatus = { deviceId: sensorId, 
        accessKey: key, 
        refreshMinutes: 0.5 ,
        darkMode:darkMode,
        language:language
    }
    return (
        <div style={{padding:'15px'}}>
            <DeviceStatus {...deviceStatus} />
        </div>
    );
}

/*sensorId={sensorId}
                    accessKey={key}
                    refreshMinutes={2}
                    */