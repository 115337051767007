import React, { useContext, useEffect } from "react";

import {  useDidMount } from "../providers";

import { LeftNavVisibilityContext } from "../providers/useLeftNavigationVisibility";

export const ContentLayoutNormal = (props: any) => {
    
    const { isVisible,setVisibilety } = useContext(LeftNavVisibilityContext);
    const didMount = useDidMount();
    useEffect(() => {
        if (didMount && setVisibilety) {
            if (!isVisible) {
                console.log('setvisible')
                setVisibilety(true);
            }
        }
    }, );
    return (
        <>
            {props.children}
        </>
    );
};


