import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";

export interface IStrings extends LocalizedStringsMethods {
    signIn: string;
    signOut: string;

    darkModeOn: string;
    darkModeOff: string;

    footerImprint: string
    footerImprintUrl: string

    footerPrivacy: string
    footerPrivacyUrl: string
    footerContactUrl: string
    footerContact: string;
    footerInfoShort:string;

    footerCopyrightTextLong: string;
    footerCopyrightTextShort: string;

    deviceNoData: string;
 

    
    telemetryTypeCo2: string;
    telemetryTypeTemp: string;
    telemetryTypeHumidity: string;
    en: string;
    de: string;

    logoAltText: string;
    subscribe: string;
    unsubscribe: string;
    subscribethis: string;
    installApp: string;
    retry: string;
    close:string;

}

export const strings: IStrings = new LocalizedStrings({
    en: {
        signIn: "SignIn",
        signOut: "SignOut",
        darkModeOn: "Dark",
        darkModeOff: "Light",

        footerImprint: "Imprint",
        footerImprintUrl: "https://www.cubido.at/impressum",
        footerPrivacy: "Privacy",
        footerPrivacyUrl: "https://www.cubido.at/datenschutz",
        footerContact: "Contact",
        footerContactUrl: "https://www.cubido.at/kontakt",
        footerInfoShort:"Leagal Information",

        footerCopyrightTextLong: "© 2021 cubido business solutions gmbh",
        footerCopyrightTextShort: "© 2021 cubido",

        deviceNoData: "no Data",
     

        telemetryTypeCo2: "Air Quality",
        telemetryTypeTemp: "Temperatur",
        telemetryTypeHumidity: "Humidity",


        contactUrl: "Contact",
        contact: "http://www.cubido.at",
        en: "English",
        de: "Deutsch",

        subscribe: "Subscribe",
        unsubscribe: "Unsubscribe",
        subscribethis: "Subscribe this",
        logoAltText: "cubido logo",
        installApp: "Install App",
        retry:"Retry",
        close:"Close"
    },
    de: {
        signIn: "Anmelden",
        signOut: "Abmelden",
        darkModeOn: "Dunkel",
        darkModeOff: "Hell",

        footerImprint: "Impressum",
        footerImprintUrl: "https://www.cubido.at/impressum",

        footerPrivacy: "Datenschutz",
        footerPrivacyUrl: "https://www.cubido.at/datenschutz",


        footerContact: "Kontakte",
        footerContactUrl: "https://www.cubido.at/kontakt",
        footerInfoShort:"Leagal Information",

        footerCopyrightTextLong: "© 2021 cubido business solutions gmbh",
        footerCopyrightTextShort: "© 2021 cubido",

        deviceNoData: "keine Daten",
       
        
        
        telemetryTypeCo2: "Luftqualität",
        telemetryTypeTemp: "Temperatur",
        telemetryTypeHumidity: "Luftfeuchtigkeit",
        contactUrl: "Kontakt",
        contact: "http://www.cubido.at",
        en: "English",
        de: "Deutsch",

        subscribe: "Benachrichtigen aktivieren",
        unsubscribe: "Benachrichtigen deaktivieren",
        subscribethis: "Benachrichtigen ändern",
        logoAltText: "cubido logo",
        installApp: "Install App",
        retry:"Nochmal laden",
        close:"Schließen"
    }
});