import { strings } from "../../localization/localization";

export const footerlinks = [
    {
        title: strings.footerContact,
        url: strings.footerContactUrl,
        external: true
    },
    {
        title: strings.footerImprint,
        url: strings.footerImprintUrl,
        external: true
    },
    {
        title: strings.footerPrivacy,
        url: strings.footerPrivacyUrl,
        external: true
    }
]