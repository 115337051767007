import { useEffect, useRef, useState } from "react";
import { ICMSNavigation, ICMSPage } from "./CMSService.types";
import { useCMSSingelton } from "./useCMSSingelton";



export function useCMSPageBySlug(slug: string) {
  const [data, setData] = useState<ICMSPage | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const cmsService = useCMSSingelton;
  const prevSlug = useRef();


  useEffect(() => {
    let isMounted = true;
    if (prevSlug.current === slug) return;
    if (!cmsService) return;
    cmsService.getPageBySlug(slug)
      .then((page: ICMSPage) => {
        if (isMounted)
          setData(page)
      })
      .catch(err => {
        console.error(err);
        setError(err);
      })
      .finally(() => {
        if (isMounted){
          setLoading(false)
        }
          
      }
      );
    /*fetch(process.env.REACT_APP_API_BASE_URL + url, init)
      .then(response => {
        if (response.ok) return response.json();
        setError(response);
      })
      .then(data => setData(data))
      .catch(err => {
        console.error(err);
        setError(err);
      })
      .finally(() => setLoading(false));
    });
    */
    return () => {
      // clean up
      isMounted = false;
    };
  }, [slug,cmsService]
  );
  return { data, loading, error };
}


export function useCMSNavigation(parentslug?: string) {
  const [data, setData] = useState<ICMSNavigation | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const cmsService = useCMSSingelton;

  useEffect(() => {
    let isMounted = true;
    if (!cmsService) return;
    cmsService.getNavigation(parentslug)
      .then((nav: ICMSNavigation) => {
        if (isMounted)
          setData(nav)
      })
      .catch(err => {
        console.error(err);
        setError(err);
      })
      .finally(() => {
        if (isMounted)
          setLoading(false)
      });
    /*fetch(process.env.REACT_APP_API_BASE_URL + url, init)
      .then(response => {
        if (response.ok) return response.json();
        setError(response);
      })
      .then(data => setData(data))
      .catch(err => {
        console.error(err);
        setError(err);
      })
      .finally(() => setLoading(false));
    });
    */
    return () => {
      // clean up
      isMounted = false;
    };
  }, [parentslug,cmsService]
  );
  return { data, loading, error };
}