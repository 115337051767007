import * as React from 'react';
//import styles from './Home.module.scss';

export interface IHomeProps {}

export interface IHomeState {}

export default class Home extends React.Component<IHomeProps, IHomeState> {

  constructor(props: IHomeProps) {
    super(props);

    this.state = {
      
    };
  }

  public render(): React.ReactElement<IHomeProps> {
    return (
      <div>
        <h1>Home</h1>
        
      </div>
    );
  }
}
