


import React, { createContext, useState } from "react";

import { IProviderProps } from "./interfaces";

export const LeftNavVisibilityContext = createContext<ILeftNavVisibilityProvider>({
    isVisible: false,

});
export interface ILeftNavVisibilityProvider {
    isVisible: boolean;
    setVisibilety?: (state: boolean) => void;
}


export const LeftNavVisibilityProvider = (props: IProviderProps) => {
    // Use State to keep the values
    const [isVisible, setStateVisibilety] = useState(true);

    const internalsetVisibilety = (state: boolean) => {
        if (isVisible !== state) {
            console.log("toggleNavVisibilioty")
            setStateVisibilety(state);
        }
    };


    // Make the context object:
    const visibilityContext = {
        isVisible: isVisible,
        setVisibilety: internalsetVisibilety,

    };

    // pass the value in provider and return
    return <LeftNavVisibilityContext.Provider value={visibilityContext}>{props.children}</LeftNavVisibilityContext.Provider>;
};
