
import React from "react";
import { IBlockData, ICMSBlock } from "../Services/CMSService.types";
import cmsstyles from '../CMS.module.scss';
import { CMSBlock } from "./CMSBlock";
import { DirectionalHint } from "@fluentui/react";
import { CMSEditModePosition } from "../CMS.Types";
import { CMSAddBlock } from "./CMSAddBlock";


export interface ICMSBlocksProps {
    sectionkey: string;
    data?: ICMSBlock[];
    editMode?: boolean;
    updateBlocks: (blocks: ICMSBlock[]) => void
}

export const CMSBlocks = (blocks: ICMSBlocksProps) => {




    return (<div className={cmsstyles.blocks}>
        {blocks.data && blocks.data.length > 0 ? blocks.data.map((b, i) => {
            return (
                <CMSBlock key={blocks.sectionkey + i} data={b}
                    index={i}
                    last={i + 1 === blocks.data?.length}
                    first={i === 0}
                    editMode={blocks.editMode}
                    blockPositionChanged={(pos: CMSEditModePosition, index: number) => {

                        const newpos = pos === CMSEditModePosition.down ? index + 1 : index - 1;
                        if (blocks.data) {
                            let cBlock = blocks.data;
                            const neworder = moveBlockTo(cBlock, index, newpos);
                            updateBlocksState(neworder);
                        }
                    }}
                    deleteBlock={(index: number) => {
                        if (blocks.data) {
                            blocks.data?.splice(index, 1);
                            updateBlocksState(blocks.data);
                        }

                    }}
                    addBlock={(pos, data, baseindex) => {

                        const postoadd = pos === CMSEditModePosition.before ? baseindex : baseindex + 1;
                        if (blocks.data) {
                            let cBlock = blocks.data;
                            cBlock.splice(postoadd, 0, data);
                            updateBlocksState(cBlock);
                        }
                    }}
                    updateBlock={(data, baseindex) => {
                        let cBlock = blocks.data;
                        if (cBlock) {
                            cBlock[baseindex] = data;
                            updateBlocksState(cBlock);
                        }

                    }}
                />

            )
        }) :
            (blocks.editMode ? (
                <div className={cmsstyles.pageActionAddSection}>
                    <CMSAddBlock
                        iconName='BoxAdditionSolid'
                        directionalHint={DirectionalHint.topCenter}
                        added={(newdata: IBlockData) => {
                            updateBlocksState([newdata])
                        }}
                    />
                </div>) : (<></>)
            )}
    </div>
    );

    function moveBlockTo(data: ICMSBlock[], from: number, to: number): ICMSBlock[] {
        var f = data.splice(from, 1)[0];
        // insert stored item into position `to`
        data.splice(to, 0, f);
        return data;
    }
    function updateBlocksState(newblocks: ICMSBlock[]) {

        blocks.updateBlocks(JSON.parse(JSON.stringify(newblocks)));
    }
}