
export interface ICMSService {
    getPageBySlug(slug: string): Promise<ICMSPage>;
    getNavigation(parentslug?:string): Promise<ICMSNavigation>;
    /*getPageById(id: number): Promise<ICMSPage>;
    addOrUpdatePage(page: ICMSPage): Promise<ICMSPage>;
    addOrUpdateNavigation(nav: any): Promise<any>;*/
}


export interface ICMSPage {
    id: number;
    slug: string;
    title: string;
    description?: string;
    author: string;
    created: Date;
    lastModifier: string;
    lastEdit: Date;
    mainSections?: ICMSSection[];
    rightSectionBlocks?: ICMSBlock[];
    header?: ICMSHeader;
}


export interface ICMSBlock {
    id: number;
    blockTypeId: number;
    headline: string;
    showHeadline: boolean;
    headlineType: CMSHeadlineType;
    properties?: IBlockData
}

export type IBlockData = any;



export interface IBlockDataTOC {
    maxLevel: CMSHeadlineType;
    showHiddenHeadlines: boolean;
}

export enum CMSHeadlineType {
    H2 = 2,
    H3 = 3,
    H4 = 4,
    H5 = 5,
}


export interface ICMSSection extends ICMSSectionBase {
    col1Blocks?: ICMSBlock[];
    col2Blocks?: ICMSBlock[];
    col3Blocks?: ICMSBlock[];
}

export interface ICMSSectionBase {
    sectionType: CMSSectionType;
}


export type ICMSHeader = ICMSHeaderColor | ICMSHeaderVideo | ICMSHeaderImage;



export interface ICMSHeaderColor extends ICMSHeaderBase {
    color: string;
}

export interface ICMSHeaderVideo extends ICMSHeaderBase {
    src: string;
}

export interface ICMSHeaderImage extends ICMSHeaderBase {
    src: string;
}


export interface ICMSHeaderBase {
    headerType: CMSHeaderType;
    showAuthor: boolean;
    showCreated: boolean;
    showModifier: boolean;
    showlastEdit: boolean;
}

export enum CMSHeaderType {
    Image,
    Color,
    Video
}
export enum CMSSectionType {
    OneColumn,
    TwoColumn,
    ThreeColumn,
    TwoColumnRight,
    TwoColumnLeft
}


export interface ICMSNavItem {
    id: number;
    slug: string;
    title: string;
    description?: string;
    icon?:string;
    img16?:string;
    navItems?: ICMSNavItem[];
}
export interface ICMSNavigation {
    //maybe more Properties
    navItems?: ICMSNavItem[];
}