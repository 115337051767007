import React from 'react';
import ReactDOM from 'react-dom';

import './styles/index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { DarkModeProvider, MessageProvider, LanguageProvider } from './providers';

import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { CookiesProvider } from 'react-cookie';


initializeIcons(/* optional base url */);
const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <React.StrictMode>

    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <CookiesProvider>
          <LanguageProvider>
            <DarkModeProvider>
              <MessageProvider>
                <App />
              </MessageProvider>
            </DarkModeProvider>
          </LanguageProvider>
        </CookiesProvider>
      </MsalProvider>
    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root')
);



//serviceWorkerRegistration.register();


reportWebVitals();