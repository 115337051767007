
import { useCMSPageBySlug } from "./Services/useCMS"
import { Helmet } from "react-helmet";

import cmsstyles from './CMS.module.scss';
import React, { useEffect, useState } from "react";
import { DirectionalHint, Spinner } from "@fluentui/react";
import { CMSHeader } from "./Header/CMSHeader";
import { CMSBlocks } from "./Block/CMSBlocks";
import { CMSSection } from "./Section/CMSSection";
import { CMSSectionType, ICMSPage, ICMSSection } from "./Services/CMSService.types";
import { CMSCommandBar } from "./EditMode/CMSCommandBar";
import { CMSEditModePosition } from "./CMS.Types";
import { CMSAddOrUpdateSectionType } from "./Section/CMSAddSection";
import { CMSAddBlock } from "./Block/CMSAddBlock";

export interface ICMSPageProps {
    slug: string;
    staticTitleSurFix?: string;
    AllowEdit?: boolean;
}

export const CMSPage = (page: ICMSPageProps) => {

    const { data, loading, error } = useCMSPageBySlug(page.slug);
    const [pageTitle, setPageTitle] = useState('');


    const [editModeData, setEditModeData] = useState<ICMSPage | null>(null);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {

        const pagetitle = !!data && !!page.staticTitleSurFix ? `${data.title} | ${page.staticTitleSurFix}` : page.staticTitleSurFix;
        setPageTitle(pagetitle ? pagetitle : '');
        if (!!data) {
            updatePageState(data)
        }

    }, [data, page.staticTitleSurFix])
    useEffect(() => {

        console.log("Data changed")

    }, [editModeData, editMode])

    /* useEffect(()=>{
         if (!!data && editMode) {
             setEditModeData(data)
         }
     },[editMode])*/
    const cmsData = editMode ? editModeData : data;
    console.log('CMSPage ' + loading ? 'Loading' : 'Loaded')
    return (
        <div className={[cmsstyles.page, editMode ? cmsstyles.editMode : cmsstyles.displayMode].join(" ")}>
            {page.AllowEdit && (<CMSCommandBar
                editMode={editMode}
                EditClicked={() => {
                    console.log("EditClicked");
                    setEditMode(true)
                }}
                SavedClicked={() => {
                    console.log("SavedClicked");
                    setEditMode(false)
                    //TODO Serverice Save
                }}
                NewClicked={() => { } // Create Page DLG
                }
                DeleteClicked={
                    () => { }
                    //Check NAV
                    //del und Redirect to/
                }
                DiscardClicked={() => {
                    console.log("DiscardClicked");
                    setEditModeData(data);
                    setEditMode(false);
                }}
            />)}

            {!!cmsData && (<CMSHeader
                title={cmsData.title}
                desciption={cmsData.description}
                created={cmsData.created}
                author={cmsData.author}
                lastEdit={cmsData.lastEdit}
                lastModifier={cmsData.lastModifier}
                data={cmsData.header}
                editmode={editMode}
                update={(title,data)=>{
                    cmsData.title =title;
                    cmsData.header=data;
                    updatePageState(cmsData);
                }}
                />)}
            {!!cmsData && (<Helmet>
                <title>{pageTitle}</title>
                {cmsData.description && (<meta name="description" content={cmsData.description} />)}
            </Helmet>)}

            <div className={cmsstyles.sectionWrapper}>
                {loading && (<div className={cmsstyles.loading}><Spinner /></div>)}
                {error !== null && (<div className={cmsstyles.error}>loc Error</div>)}
                {!loading && !cmsData && (<div className={cmsstyles.nodata}>loc NoData</div>)}
                {!loading && error == null && !!cmsData && (<>
                    <div className={cmsstyles.mainSections}>
                        {cmsData.mainSections && cmsData.mainSections.length > 0 ?
                            cmsData.mainSections.map((s, i) => {
                                return (<CMSSection key={'Section' + i}
                                    editMode={editMode}
                                    sectionkey={'mainSection' + i}
                                    data={s}
                                    index={i}
                                    first={i === 0}
                                    last={i + 1 === cmsData.mainSections?.length}
                                    sectionTypeChanged={(sectionType: CMSSectionType, index: number) => {
                                        s.sectionType = sectionType;
                                        updatePageState(cmsData);
                                    }}
                                    sectionPositionChanged={(pos: CMSEditModePosition, index: number) => {
                                        const newpos = pos === CMSEditModePosition.down ? index + 1 : index - 1;
                                        if (cmsData.mainSections) {
                                            cmsData.mainSections = moveSectionTo(cmsData.mainSections, index, newpos);
                                            updatePageState(cmsData);
                                        }

                                    }}
                                    addSection={(pos: CMSEditModePosition, sectionType: CMSSectionType, index: number) => {
                                        const postoadd = pos === CMSEditModePosition.before ? index : index - 1;
                                        cmsData.mainSections?.splice(postoadd, 0, {
                                            sectionType: sectionType,
                                        });
                                        updatePageState(cmsData);
                                    }}
                                    deleteSection={(index: number) => {
                                        cmsData.mainSections?.splice(index, 1);
                                        updatePageState(cmsData);
                                    }}
                                    updateSection={(updatedsection: ICMSSection, index: number) => {
                                        if (cmsData.mainSections) {
                                            cmsData.mainSections[index] = updatedsection
                                            updatePageState(cmsData);
                                        }

                                    }}
                                />);
                            }) :
                            (editMode ? (
                                <div className={cmsstyles.pageActionAddSection}>
                                    <CMSAddOrUpdateSectionType
                                        iconName='CircleAdditionSolid'
                                        directionalHint={DirectionalHint.topCenter}
                                        changed={(sectionType: CMSSectionType) => {
                                            cmsData.mainSections = [{
                                                sectionType: sectionType,
                                            }];
                                            updatePageState(cmsData);

                                        }}
                                    /></div>) : (<></>))}
                    </div>

                    {cmsData.rightSectionBlocks && cmsData.rightSectionBlocks.length > 0 ? (
                        <div className={cmsstyles.rightSection}>
                            <CMSBlocks sectionkey={'rightSection'} data={cmsData.rightSectionBlocks}
                                editMode={editMode}
                                updateBlocks={(blocks) => {
                                    cmsData.rightSectionBlocks = blocks;
                                    updatePageState(cmsData);
                                }}
                            />
                        </div>
                    ) : (editMode ? (
                        <div className={cmsstyles.pageActionAddSection}>
                            <CMSAddBlock
                                iconName='BoxAdditionSolid'
                                directionalHint={DirectionalHint.topCenter}
                                added={(newdata) => {
                                    cmsData.rightSectionBlocks = [newdata];
                                    updatePageState(cmsData);
                                }}
                            />
                        </div>) : (<></>))}
                </>)}
            </div>

        </div>
    );
    function moveSectionTo(data: ICMSSection[], from: number, to: number): ICMSSection[] {
        var f = data.splice(from, 1)[0];
        // insert stored item into position `to`
        data.splice(to, 0, f);
        return data;
    }
    function updatePageState(page: ICMSPage) {

        setEditModeData(JSON.parse(JSON.stringify(page)));
    }
}

