import * as React from 'react';
import { IDeviceStatusProps } from './DeviceStatus';
import { IDeviceData, IDeviceDecoder, IDeviceDecoders, TelemetryType } from "./DeviceStatus.type";
import MainTwoDetailDevice from './DeviceRenderer/MainTwoDetailDevice';
import TwoMainDevice from './DeviceRenderer/TwoMainDevice';
import GenericDevice from './DeviceRenderer/GenericDevice';

export type DeviceDecoderType = 'airwitpm' | 'airwitco2r52' | 'airwitth'| 'unknown'

export const DeviceDecoders: IDeviceDecoders = {
    'airwitpm': {
        mainType: TelemetryType.co2,
        label: { de: "Feinstaub", en: "Particulate Matter" },
        iconpart: "smoke",
        renderer: (deviceStatusProps:IDeviceStatusProps,devicedata?: IDeviceData): JSX.Element => {
            return (<GenericDevice 
                deviceStatus={deviceStatusProps} 
                data={devicedata}
                decoder={DeviceDecoders['airwitpm']}
            />);
        }
    },
    'airwitco2r52': {
        mainType: TelemetryType.co2,
        label: { de: "Luftqualitätsansicht", en: "Air Quality Viewer" },
        iconpart: "AirQuality",
        renderer: (deviceStatusProps:IDeviceStatusProps,devicedata?: IDeviceData): JSX.Element => {
            return (<MainTwoDetailDevice 
                deviceStatus={deviceStatusProps} 
                data={devicedata}
                decoder={DeviceDecoders['airwitco2r52']}
            />);
        }
    },
    'airwitth': {
        mainType: TelemetryType.temperature,
        label: { de: "Luft Druck Sensor", en: "Air Pressure Guard" },
        iconpart: "wind",
        renderer: (deviceStatusProps:IDeviceStatusProps,devicedata?: IDeviceData): JSX.Element => {
            return (<TwoMainDevice 
                deviceStatus={deviceStatusProps} 
                data={devicedata}
                decoder={DeviceDecoders['airwitth']}
            />);
        }
    },
    'airwitpressguard':
    {
        mainType: TelemetryType.temperature,
        label: { de: "Raumfühler", en: "Room Sensor" },
        iconpart: "pressure",
        renderer: (deviceStatusProps:IDeviceStatusProps,devicedata?: IDeviceData): JSX.Element => {
            return (<TwoMainDevice 
                deviceStatus={deviceStatusProps} 
                data={devicedata}
                decoder={DeviceDecoders['airwitpressguard']}
            />);
        }
    },

}

export const UnkownDecoder: IDeviceDecoder = {
    mainType: TelemetryType.unkown,
    label: { de: "Unbekanntes Gerät", en: "Unknown Device" },
    iconpart: "AirQuality",
    renderer: (deviceStatusProps:IDeviceStatusProps,devicedata?: IDeviceData): JSX.Element => {
        return (<GenericDevice 
            deviceStatus={deviceStatusProps} 
            data={devicedata}
            decoder={DeviceDecoders['airwitth']}
        />);
    }
}



