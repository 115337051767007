
import { IBlockData } from "../../Services/CMSService.types";
import { ICMSBlockTypeProps } from "../ICMSBlock";
import { CMSImageBlock } from "./Image/CMSImageBlock";
import { CMSTextBlock } from "./Text/CMSTextBlock";

export interface ICMSBlockTypes {
    name: string;
    icon?: string;
    imgsrc?: string;
    id: number;
    default?: IBlockData,
    renderer: (props: ICMSBlockTypeProps) => JSX.Element;
}

export const CMSBlockTypes: ICMSBlockTypes[] = [
    CMSTextBlock,
    CMSImageBlock
]