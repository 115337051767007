import { DefaultButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { strings } from "../localization/localization";
import { WebPush } from "../subscription";

export type RegisterSubscriptionProps = {
    sensorId: string;
}

export const RegisterSubscription: React.FC<RegisterSubscriptionProps> = ({ sensorId }) => {
    const [data, setData] = useState({
        isWebPushAvailable: false,
        isWebPushRegisterd: false,
        isThisWebPushRegisterd: false,
        isLoaded: false
    })
    useEffect(() => {
        const fetchInfo = async () => {
            const isWebPushAvailable: boolean = await WebPush.isWebPushAvailable();
            const isWebPushRegisterd: boolean = await WebPush.isWebPushRegisterd();
            const isThisWebPushRegisterd: boolean = await WebPush.isThisWebPushRegisterd(sensorId);

            setData({
                isWebPushAvailable: isWebPushAvailable,
                isWebPushRegisterd: isWebPushRegisterd,
                isThisWebPushRegisterd: isThisWebPushRegisterd,
                isLoaded: true
            });
        }
        fetchInfo()



    }, [sensorId]);
    if (!data.isLoaded) {
        return (<div></div>);
    }
    if (data.isWebPushAvailable) {

        if (data.isWebPushRegisterd) {
            if (data.isThisWebPushRegisterd) {
                return (<DefaultButton text={strings.subscribe} onClick={async () => {
                    let allowed = WebPush.isWebPushGranted();
                    if (!allowed) 
                        allowed = await WebPush.askPermission();
                    if (allowed) 
                        WebPush.subscribeSensor(sensorId);
                }} />);
            } else {
                return (<DefaultButton text={strings.subscribethis}
                    onClick={async () => {
                        let allowed = WebPush.isWebPushGranted();
                        if (!allowed) 
                            allowed = await WebPush.askPermission();
                        if (allowed) {
                            await WebPush.unsubscribeSensor();
                            await WebPush.subscribeSensor(sensorId);
                        }
                        
                    }
                    }
                />);
            }
        } else {
            return (<DefaultButton text={strings.unsubscribe}
                onClick={() => {
                    WebPush.unsubscribeSensor();
                }} />);
        }

    }
    return (<div>You Have Blocked or Browser does not support this or your Browseris in Private Mode</div>);

};