
import React,{ createContext, useState } from "react";
import { IMessage } from "../interfaces/IMessages.type";
import { IProviderProps } from "./interfaces";


export const MessageContext = createContext<IMessageProvider>({

    messages: []
});
export interface IMessageProvider {
    messages: IMessage[];
    addMessage?: (data: IMessage) => void;
    removeMessage?: (index: number) => void;
    clearMessages?: () => void;
}

export const MessageProvider = (props: IProviderProps) => {

    const [messages, setMessages] = useState([] as IMessage[]);

    const addMessage = (data: IMessage) => {
        messages.push(data);
        setMessages(Array.from(messages));
    }
    const removeMessage = (index: number) => {
        messages.splice(index, 1);
        setMessages(Array.from(messages));
    }
    const clearMessages = () => {
        setMessages([]);

    }



    // Make the context object:
    const messageContext = {
        messages: messages,
        addMessage: addMessage,
        removeMessage: removeMessage,
        clearMessages: clearMessages,
    };

    // pass the value in provider and return
    return <MessageContext.Provider value={messageContext}>{props.children}</MessageContext.Provider>;
};




