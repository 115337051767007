import React, { useContext, useEffect, useState } from "react";


import { ContentLayoutNoNav, ContentLayoutNormal, MasterPageLayout } from "./pageLayouts";

import "./styles/App.css";
import { Route, Switch } from "react-router-dom";
import Home from "./publicPages/Home";
import { PageNotFound } from "./publicPages/PageNotFound";

import { SensorInformation } from "./publicPages/SensorInformation";
import GuardedRoute from "./utils/GuardedRoute";

import { ThemeProvider } from "@fluentui/react";
import { darkTheme, lightTheme } from "./theme/theme";
import { DarkModeContext, useDidMount } from "./providers";
import { Devices } from "./IIOT/Devices";
import * as serviceWorker from './serviceWorkerRegistration';

import { CMSPage } from "./CMS/CMSPage";

import PageLayoutNoNavRoute from "./utils/PageLayoutNoNavRoute";
import { useCMSNavigation } from "./CMS/Services/useCMS";
import { CMSLoadingNavPage } from "./CMS/CMSLoadingNav";
import { ICMSNavItem } from "./CMS/Services/CMSService.types";
import { globalConfig } from "./globalconf";
import { LeftNavVisibilityProvider } from "./providers/useLeftNavigationVisibility";
import { withCookies } from 'react-cookie';
import { useCookies } from "react-cookie";



export function App() {

    const { darkMode, toggleDarkMode } = useContext(DarkModeContext);
    const { data, loading } = useCMSNavigation('');
    const [cmsNavItems, setCmsNavItems] = useState<string[]>([]);
    const [cookies] = useCookies(['theme']);
    const didMount = useDidMount();

    useEffect(() => {
        async function loadServiceWorker() {
            await new Promise(r => {
                serviceWorker.register({
                    onSuccess: (registration) => {
                        r('');
                    }
                })
            });
        }
        if (globalConfig.features.enablelogin) {
            loadServiceWorker();
        }

    });

    useEffect(() => {
        if (didMount) {
            const val = cookies['theme']
            if (!!val) {
                if (val === 'dark') {
                    if (!!toggleDarkMode) {
                        toggleDarkMode();
                    }
                }
            } else {

                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    if (!!toggleDarkMode) {
                        if (!darkMode) {
                            toggleDarkMode()
                        }
                    }
                }

            }
        }
    }, [didMount])

    useEffect(() => {
        function flatNav(nodes: ICMSNavItem[]): string[] {
            let data: string[] = [];
            nodes.forEach(node => {
                data.push(node.slug);
                if (node.navItems) {
                    const subSlugs: string[] = flatNav(node.navItems);
                    data = data.concat(subSlugs);
                }
            });
            return data;
        }
        if (!loading && data && data.navItems && data.navItems.length > 0) {
            const navstruct = flatNav(data.navItems)
            console.log(navstruct);
            setCmsNavItems(navstruct);
        }
    }, [data, loading])

    return (
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme} applyTo={'body'}  >
            <LeftNavVisibilityProvider>


                <MasterPageLayout>
                    <Switch>
                        <Route exact path="/" component={() => <ContentLayoutNormal><Home /></ContentLayoutNormal>} />
                        <Route path="/test1" component={() => <ContentLayoutNoNav><Home /></ContentLayoutNoNav>} />
                        <Route path="/test2" component={() => <ContentLayoutNoNav><Home /></ContentLayoutNoNav>} />
                        <Route path="/test3" component={() => <ContentLayoutNormal><Home /></ContentLayoutNormal>} />
                        <Route path="/test4" component={() => <ContentLayoutNormal><CMSPage AllowEdit slug={'/test4'} staticTitleSurFix={'cool'} /></ContentLayoutNormal>} />

                        <Route exact path="/Register" component={() => <Home />} />

                        <PageLayoutNoNavRoute exact path="/SensorInformation/:sensorId/:key" Component={SensorInformation} />
                        {/* sollte auch keintoggle noetig sein */}
                        <GuardedRoute exact path="/iiot/" Component={() => { return (<div>home</div>) }} />
                        <GuardedRoute exact path="/iiot/devices" Component={() => { return (<Devices />) }} />
                        <GuardedRoute exact path="/iiot/profileWithMsal" Component={() => { return (<div>profileWithMsal</div>) }} />
                        <GuardedRoute exact path="/iiot/profileRawContext" Component={() => { return (<div>profileRawContext</div>) }} />

                        {cmsNavItems.map((n, i) => {
                            return (<Route key={'routekey' + i} exact path={n} component={() => <ContentLayoutNormal>
                                <CMSPage slug={n} staticTitleSurFix={"IOT Central Custom App"} />
                            </ContentLayoutNormal>
                            } />);
                        })}
                        <Route component={() => loading ? <CMSLoadingNavPage /> : <PageNotFound />} />
                    </Switch>
                </MasterPageLayout>

            </LeftNavVisibilityProvider>
        </ThemeProvider>

    );
}

export default withCookies(App);
//<Route exact path="/test" component={CMSMaster} />
//<Route exact path="/test2" component={() => <CMSPage slug={"/test2"} staticTitleSurFix="IOT Central Custom App" />} />
//<Route path="/*" component={PageNotFound} />
// <CMSRoutes staticTitleSurFix="IOT Central Custom App" />
/*

<CMSRoutes staticTitleSurFix="IOT Central Custom App"
wrapperComponent={ContentLayoutNormal}
pageNotFound={<PageNotFound />}
/>
*/