import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import React from 'react';
import { Route,  RouteComponentProps } from "react-router-dom";


interface Props {
    Component: React.FC<RouteComponentProps>;
    path: string;
	exact?: boolean;

}

const GuardedRoute = ({ Component,path,exact,...rest }: Props) => {

 

    return (
        <Route path={path} exact={exact} render={(props:RouteComponentProps) => (
            <div>
                <AuthenticatedTemplate>
                    <Component {...props} />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                Please Use Login Button
                
              
                </UnauthenticatedTemplate>
            </div>
        )} />

    )
};


export default GuardedRoute;