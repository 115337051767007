import { TextField, updateA } from "@fluentui/react";
import React from "react";
import { ICMSBlockTypeProps } from "../../ICMSBlock";
import imagestyles from './CMSImageBlock.module.scss';

export interface IBlockDataImage {
    src: string;
    alt: string;
}



const CMSImageBlockReact = (props: ICMSBlockTypeProps) => {
    const imageprops = props.properties as IBlockDataImage;
    return (<div className={imagestyles.imageblock}>
        {props.editMode && (<div className={imagestyles.editProps}>
            <TextField placeholder={'loc src'} key={'imgsrc' + props.index}
                onChange={(event, newValue) => { props.update({ src: newValue }) }}
                value={imageprops?.src} />
            <TextField placeholder={'loc alt'} key={'imgalt' + props.index}
                onChange={(event, newValue) => { props.update({ alt: newValue }) }}
                value={imageprops?.alt} />
        </div>)}
        {  imageprops && imageprops.src && (<img src={imageprops.src} alt={imageprops?.alt} />)
        }
    </div>
    );;


}

export const CMSImageBlock = {
    name: 'Image',
    icon: 'FileImage',
    id: 2,

    renderer: CMSImageBlockReact,
}