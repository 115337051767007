import React, { ReactNode } from "react";
import { CMSBlocks } from "../Block/CMSBlocks";
import { CMSSectionType, ICMSBlock, ICMSSection } from "../Services/CMSService.types";
import cmsstyles from '../CMS.module.scss';
import { CMSEditModePosition } from "../CMS.Types";
import { DirectionalHint, IconButton } from "@fluentui/react";
import { CMSAddOrUpdateSectionType } from "./CMSAddSection";

export interface ICMSSectionProps {
    sectionkey: string;
    data: ICMSSection;

    index: number;
    last: boolean;
    first: boolean;
    editMode?: boolean;
    updateSection: (section: ICMSSection, index: number) => void;
    sectionTypeChanged: (sectionType: CMSSectionType, index: number) => void;
    sectionPositionChanged: (pos: CMSEditModePosition, index: number) => void;
    addSection: (pos: CMSEditModePosition, sectionType: CMSSectionType, index: number) => void;
    deleteSection: (index: number) => void;
}

export const CMSSection = (section: ICMSSectionProps) => {
    let part = (<div></div>);
    
    switch (section.data.sectionType) {
        case CMSSectionType.OneColumn:

            part = (<div className={cmsstyles.section}>
                <div className={[cmsstyles.oneColumn, cmsstyles.col].join(" ")}>
                    {AddBlockArea('colOne',1, section.data.col1Blocks)}
                </div></div>);
            break;
        case CMSSectionType.TwoColumn:

            part = (
                <div className={cmsstyles.section}>
                    <div className={[cmsstyles.twoColumn, cmsstyles.col].join(" ")}>
                        {AddBlockArea('colTwoOne',1, section.data.col1Blocks)}

                    </div>
                    <div className={[cmsstyles.twoColumn, cmsstyles.col].join(" ")}>
                        {AddBlockArea('colTwoTwo',2, section.data.col2Blocks)}
                    </div>
                </div>);
            break;
        case CMSSectionType.ThreeColumn:

            part = (<div className={cmsstyles.section}>
                <div className={[cmsstyles.threeColumn, cmsstyles.col].join(" ")}>
                {AddBlockArea('colThreeOne',1, section.data.col1Blocks)}
                </div>
                <div className={[cmsstyles.threeColumn, cmsstyles.col].join(" ")}>
                {AddBlockArea('colThreeTwo',2, section.data.col2Blocks)}
                </div>
                <div className={[cmsstyles.threeColumn, cmsstyles.col].join(" ")}>
                {AddBlockArea('colThreeThree',3, section.data.col3Blocks)}
                </div>
            </div>);
            break;
        case CMSSectionType.TwoColumnLeft:

            part = (<div className={cmsstyles.section}>
                <div className={[cmsstyles.twoColumnLeft, cmsstyles.col].join(" ")}>
                {AddBlockArea('colTwoOneLeft',1, section.data.col1Blocks)}
                </div>
                <div className={[cmsstyles.twoColumn, cmsstyles.col].join(" ")}>
                {AddBlockArea('colTwoTwoLeft',2, section.data.col2Blocks)}
                 
                </div></div>);
            break;
        case CMSSectionType.TwoColumnRight:

            part = (<div className={cmsstyles.section}>
                <div className={[cmsstyles.twoColumn, cmsstyles.col].join(" ")}>
                {AddBlockArea('colTwoOneRight',1, section.data.col1Blocks)}
                </div>
                <div className={[cmsstyles.twoColumnRight, cmsstyles.col].join(" ")}>
                {AddBlockArea('colTwoTwoRight',2, section.data.col2Blocks)}
                </div></div>);
            break;

    }

    return (<>
        {section.editMode ?
            (<div className={cmsstyles.editSectionWrapper}>
                <div className={cmsstyles.actionBefore}>
                    <CMSAddOrUpdateSectionType
                        iconName='CircleAdditionSolid'
                        directionalHint={DirectionalHint.bottomCenter}
                        changed={(sectionType: CMSSectionType) => {

                            if (section.addSection)
                                section.addSection(CMSEditModePosition.before, sectionType, section.index);
                        }}
                    />
                </div>
                <div className={cmsstyles.actionLayer}>
                    <CMSAddOrUpdateSectionType
                        iconName='MapLayers'
                        directionalHint={DirectionalHint.leftTopEdge}
                        changed={(sectionType: CMSSectionType) => {

                            if (section.sectionTypeChanged)
                                section.sectionTypeChanged(sectionType, section.index);
                        }}
                    />
                    {!section.first && (<IconButton iconProps={{ iconName: 'Up' }} onClick={() => { if (section.sectionPositionChanged) section.sectionPositionChanged(CMSEditModePosition.up, section.index); }} />)}
                    {!section.last && (<IconButton iconProps={{ iconName: 'Down' }} onClick={() => { if (section.sectionPositionChanged) section.sectionPositionChanged(CMSEditModePosition.down, section.index); }} />)}
                    <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => { if (section.deleteSection) section.deleteSection(section.index); }} />
                </div>
                {part}
                <div className={cmsstyles.actionAfter}>
                    <CMSAddOrUpdateSectionType
                        iconName='CircleAdditionSolid'
                        directionalHint={DirectionalHint.topCenter}
                        changed={(sectionType: CMSSectionType) => {

                            if (section.addSection)
                                section.addSection(CMSEditModePosition.after, sectionType, section.index);
                        }}
                    />
                </div>
            </div>) :
            part}
    </>
    );
    function AddBlockArea(sectionPart: string, col:number , blocks?: ICMSBlock[]): ReactNode {
        return (<CMSBlocks sectionkey={section.sectionkey + sectionPart}
            data={blocks} 
            editMode={section.editMode}
            updateBlocks={(newblocks) => {
                (section.data as any)[`col${col}Blocks`]  =newblocks
                if (section.updateSection) {
                    section.updateSection(section.data, section.index)
                }
            }} />)
    }

}