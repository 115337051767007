import React, { useContext } from "react";

import { Stack, useTheme } from "@fluentui/react";
import { DarkModeContext } from "../providers";
import styles from './../ui.module.scss'
//import { strings } from "../localization/localization";
import { FooterBar, MessageArea, NavPanel, TopBar } from ".";
import { globalConfig } from "../globalconf";
import { LeftNavVisibilityContext } from "../providers/useLeftNavigationVisibility";

export const MasterPageLayout = (props: any) => {
    const { darkMode } = useContext(DarkModeContext);
    const { isVisible } = useContext(LeftNavVisibilityContext);
    const theme = useTheme();
    

    return (
        <>
            <TopBar
                logo={darkMode ? globalConfig.logoimg.dark : globalConfig.logoimg.light}
                logoshort={darkMode ? globalConfig.logoimgsmall.dark : globalConfig.logoimgsmall.light}
                logolink={globalConfig.logourl}
                logotarget={globalConfig.logotarget}
            />
            <Stack horizontal grow={1} className={styles.Content} >
                {globalConfig.features.showNavigation &&  isVisible && (<Stack verticalFill
                className="ms-hiddenMdDown"
                    style={{
                        padding: '15px',
                        backgroundColor: theme.palette.white,
                        boxShadow: theme.effects.elevation8
                    }} >
                    <NavPanel AllowMinimize={true} />
                </Stack>)}
                <Stack verticalFill grow={1}
                className={styles.WrapContent}
                >
                    <MessageArea />
                    {/*<Stack>

                        <Breadcrumb
                            items={items}
                            // Returning undefined to OnReduceData tells the breadcrumb not to shrink
                            onReduceData={() => undefined}
                            maxDisplayedItems={3}
                            ariaLabel="Breadcrumb with static width"
                            overflowAriaLabel="More items"
                        />
                    </Stack>
                    */}
                    <Stack style={{
                        padding: '0px'
                    }}
                    className={styles.MainContent}
                    >
                        {props.children}
                    </Stack>
                </Stack>
            </Stack>
            <FooterBar />

        </>
    );
};


