import * as React from 'react';
import { IDeviceStatusProps } from '../DeviceStatus';
import { IDeviceData, IDeviceDecoder, ITelemetry, TelemetryType } from '../DeviceStatus.type';
import { TelemetryBoxRenderer } from '../TelemetryBoxRenderer/TelemetryBoxRenderer';
import { IDeviceRenderProps } from './DeviceRender.type';
import styles from './MainTwoDetailDevice.module.scss';



export interface IMainDetailDeviceState {
    telemetry?: ITelemetry
}

export default class MainTwoDetailDevice extends React.Component<IDeviceRenderProps, IMainDetailDeviceState> {

    constructor(props: IDeviceRenderProps) {
        super(props);

        this.state = {

        };
    }


    public render(): React.ReactElement<IDeviceRenderProps> {
        if (!this.props.data) {
            return (<div></div>);
        }
        const related = this.props.data.telemetry.filter(t => t.telemetryType === this.props.decoder.mainType);
        if (related.length === 0) {
            return (<div></div>);
        }


        return (<div className={styles.telemetryView}>
            <div className={styles.mainLeftTelemetry}>
                {TelemetryBoxRenderer(related[0],this.props.deviceStatus.darkMode, true)}
            </div>
            <div className={styles.secondaryTelemetry}>
                {

                    this.props.data.telemetry.filter(t => t.telemetryType !== this.props.decoder.mainType).map((x, i) => {
                        return TelemetryBoxRenderer(x,this.props.deviceStatus.darkMode, false,i);
                    })

                }
            </div>
        </div>);

    }
    
}


