import { ITelemetry, TelemetryType } from "../DeviceStatus.type";
import * as React from 'react';
import styles from './TelemetryBoxRenderer.module.scss';
import { TelemetryTypeDefinitions, UnkownTelemetryType } from "../TelemetryTypeDefinitions";

export function TelemetryBoxRenderer(telemetry: ITelemetry, darkMode?: boolean, main?: boolean, index?: number): JSX.Element {
    let unit: string = telemetry.telemetryMeasurement || '';
    let iconPart = 'unknown'
    let telemetryTypeDefinition = TelemetryTypeDefinitions.filter(t=> t.telemetryType===telemetry.telemetryType);
    if(telemetryTypeDefinition && telemetryTypeDefinition.length >0) {
        iconPart= telemetryTypeDefinition[0].iconPart
    } else {
        iconPart=  UnkownTelemetryType.iconPart;
    }
   
    const key= `${main ? 'maintel' : 'sub'}${typeof(index) ===  undefined?'u':index }`
    if(iconPart === 'unknown'){

        return (<div key={key} className={main ? styles.mainTelemetry : styles.telemetry}>
             <div className={styles.telemetryIcon}>
            <div className={styles.noimg}></div></div>
            <div className={styles.telemetryValues}>
                <span className={styles.telemetryValue}>
                    {telemetry.telemetryValue}
                </span>
                <span className={styles.telemetryUnit}>{unit}
                </span>
            </div>
        </div>)
    }
    const icon = `/TelemetryImages/${iconPart}.svg`;
    const icondark = `/TelemetryImages/${iconPart}_white.svg`;
    return (<div key={key} className={main ? styles.mainTelemetry : styles.telemetry}>
        <div className={styles.telemetryIcon}>
            <img src={darkMode ? icondark : icon} alt='locTelemetryIcon' /></div>
        <div className={styles.telemetryValues}>
            <span className={styles.telemetryValue}>
                {telemetry.telemetryValue}
            </span>
            <span className={styles.telemetryUnit}>{unit}
            </span>
        </div>
    </div>)

}