import { Callout, ChoiceGroup, DirectionalHint, IChoiceGroupOption, IconButton } from "@fluentui/react";
import React from "react";
import { useState } from "react";
import { CMSHeadlineType, IBlockData, ICMSBlock } from "../Services/CMSService.types";
import { useId } from '@fluentui/react-hooks';
import { CMSBlockTypes } from "./BlockTypes";


export interface ICMSAddBlockProps {
    directionalHint?: DirectionalHint;
    iconName: string;
    added: (newdata: ICMSBlock) => void;

}



export const CMSAddBlock = (props: ICMSAddBlockProps) => {
    const [isCalloutVisible, setIsCalloutVisible] = useState(false);
    const buttonId = useId('callout-section-button');

    const options: IChoiceGroupOption[] = CMSBlockTypes.map(x => {
        return ({
            key: x.id.toString(),
            imageSize: { width: 32, height: 32 },
            iconProps: x.icon ? { iconName: x.icon } : undefined,
            imagesrc: x.imgsrc ? x.imgsrc : undefined,
            text: x.name,
        })
    });
    return (
        <div>
            <IconButton
                id={buttonId}
                iconProps={{ iconName: props.iconName }}
                onClick={() => { setIsCalloutVisible(!isCalloutVisible) }}
            />
            {isCalloutVisible ? (
                <Callout
                    gapSpace={0}
                    target={`#${buttonId}`}

                    onDismiss={() => { setIsCalloutVisible(false); }}
                    directionalHint={props.directionalHint}
                    setInitialFocus
                >
                    <ChoiceGroup label="Pick one Sectiontyp"

                        options={options}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                            if (option) {
                                setIsCalloutVisible(false);
                                AddBlock(+option.key);
                            }

                        }}
                    />;
                </Callout>
            ) : null
            }
        </div>);

    function AddBlock(blockType: number): void {
        const blocktypes = CMSBlockTypes.filter(x => x.id === blockType);
        let blockdefaultValue: ICMSBlock = {
            id: -1,
            blockTypeId: blockType,
            headline: '',
            showHeadline: true,
            headlineType: CMSHeadlineType.H2,

        };

        if (blocktypes.length > 0) {
            blockdefaultValue.headline = blocktypes[0].name;
            blockdefaultValue.properties = blocktypes[0].default;
        }


        props.added(blockdefaultValue);
    }
}