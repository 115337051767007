import { ICMSNavigation, ICMSPage, ICMSService } from "./CMSService.types";

export class CMSService implements ICMSService {

    constructor(){
        console.log("CMSService init");
    }
    async getPageBySlug(slug: string): Promise<ICMSPage> {
        return new Promise((resolve,reject)=>{
            setTimeout(() => {
                const data = require('./CMSPageSample.json');
                resolve(data);
            } ,1);
        });
    }
    /*
    async getPageNavById(id: string): Promise<ICMSPageNav> {
        return new Promise((resolve,reject)=>{
            setTimeout(() => {
                const data = require('./CMSPageSample.json');
                resolve(data);
            } ,1);
        });
    }*/
    /*
    async savePage(data: ICMSPage): Promise<ICMSPage> {
        return new Promise((resolve,reject)=>{
            setTimeout(() => {
                const data = require('./CMSPageSample.json');
                resolve(data);
            } ,1);
        });
    }*/
    /*
    async deletePage(id: string): Promise<ICMSPage> {
        return new Promise((resolve,reject)=>{
            setTimeout(() => {
                const data = require('./CMSPageSample.json');
                resolve(data);
            } ,1);
        });
    }*/
    async getNavigation(parentslug?:string): Promise<ICMSNavigation> {
        return new Promise((resolve,reject)=>{
            setTimeout(() => {
                console.log("CMSNavSample");
                const data = require('./CMSNavSample.json');
                resolve(data);
            } ,1);
        });
    }
  /*  async  getPageById(id: number): Promise<ICMSPage> {
        return new Promise((resolve,reject)=>{
            setTimeout(() => {
                const data = require('./CMSSample.json');
                resolve(data);
            } ,500);
        });
    }
    async  addOrUpdatePage(page: ICMSPage): Promise<ICMSPage> {
        return new Promise((resolve,reject)=>{
            setTimeout(() => {
                if(!page.id || page.id <1) {
                    //New!!
                    page.id=123;
                }
                resolve(page);
            } ,500);
        });
    }
    async addOrUpdateNavigation(nav: any): Promise<any> {
        return new Promise((resolve,reject)=>{
            setTimeout(() => {
                
                resolve({});
            } ,500);
        });
    }*/

}