import React from "react";
import { CMSHeaderType, ICMSHeader, ICMSHeaderImage } from "../Services/CMSService.types";
import cmsstyles from '../CMS.module.scss';

import { TextField, useTheme } from "@fluentui/react";

export interface ICMSHeaderProps {
    title: string;
    desciption?: string;
    author: string;
    created: Date;
    lastModifier: string;
    lastEdit: Date;
    data?: ICMSHeader;
    editmode?: boolean;
    update: (title: string, data?: ICMSHeader) => void;
}

export const CMSHeader = (header: ICMSHeaderProps) => {
    const theme = useTheme();

    const headlinePlaceholder = header.editmode ?
        (<textarea placeholder={'loc Headline'} key={'headlineheader'}
            onChange={(event) => { header.update(event.target.value, header.data) }}
            value={header.title}></textarea>) :
        (<span>{header.title}</span>)

    let part = (<div><h1>{headlinePlaceholder}</h1></div>);

    if (!!header.data) {

        const headercreator = header.data.showAuthor ? (<span className={cmsstyles.headeruser}>{header.author}</span>) : null;
        const headercreated = header.data.showCreated ? (<span className={cmsstyles.headerdate}>{new Date(header.created).toLocaleString()}</span>) : null;
        const headermodi = header.data.showModifier ? (<span className={cmsstyles.headeruser}>{header.lastModifier}</span>) : null;
        const headerlastEdit = header.data.showlastEdit ? (<span className={cmsstyles.headerdate}>{new Date(header.lastEdit).toLocaleString()}</span>) : null;
        let headerinfo = null;
        let extraheight: boolean = false;
        if (headercreator || headercreated || headermodi || headerlastEdit) {
            if (header.data.showAuthor && header.data.showModifier && header.data.showCreated && header.data.showlastEdit
                && header.author === header.lastModifier && header.created === header.lastEdit) {
                extraheight = true;
                headerinfo = (<div className={cmsstyles.editinfo + ' ms-bgColor-themePrimary'}><div>{headercreator}{headercreated}</div></div>)
            } else {
                extraheight = true;
                headerinfo = (<div className={cmsstyles.editinfo} style={{ backgroundColor: theme.palette.themeDark }}><div>{headercreator}{headercreated}</div><div>{headermodi}{headerlastEdit}</div></div>)
            }


        }
        switch (header.data.headerType) {
            case CMSHeaderType.Color:
                part = (<div className={cmsstyles.headerInfo}><h1>{headlinePlaceholder}</h1>
                //colorcahnger
                </div>);
                break;
            case CMSHeaderType.Image:
                const img = header.data as ICMSHeaderImage;
                let headercss = cmsstyles.headerimage
                if (extraheight) {
                    headercss += ` ${cmsstyles.headerExtraHeight}`;
                }

                part = (<div className={headercss}
                    style={(img && img.src) ? { backgroundImage: `url("${img.src}")` } : {}}>
                    {header.editmode && (<TextField placeholder={'loc hederbg'} key={'imgheader'}
                        onChange={(event, newValue) => {
                            let data: any = header.data ? header.data : {};
                            data.src = newValue;
                            header.update(header.title, data)
                        }}
                        value={img.src} />)}
                    <div className={cmsstyles.headerInfo} >
                        <h1>{headlinePlaceholder}</h1>
                        {headerinfo}
                    </div>
                </div>);
                break;
            case CMSHeaderType.Video:
                part = (<div><h1>{headlinePlaceholder}</h1></div>);
                break;

        }
    }


    return (<div className={cmsstyles.header}>
        { part}
    </div>
    );
}