import React from "react";


export const CMSLoadingNavPage = () => {
 
    /*<CMSPage slug={"/CMSLoadingNav"} staticTitleSurFix={props.staticTitleSurFix} />*/
    return (<div>
        LOADING
    

    </div>);
}