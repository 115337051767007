
import { Dropdown, IDropdownOption } from '@fluentui/react';
import React from 'react';
import { useContext } from 'react';
import { strings } from '../../localization/localization';
import { LanguageContext } from '../../providers';

export const LanguageButton = () => {
    const userContext = useContext(LanguageContext);
    const { setLanguage } = userContext;
    const lang = strings.getAvailableLanguages();
    const currentlang = strings.getLanguage();
    const options: IDropdownOption[] = lang.map((x) => {
        return ({
            key: x,
            selected: x === currentlang,
            text: (strings as any)[x],

        })
    })
    if (options.length < 2) {
        return (<></>);
    }

    return (
        <Dropdown options={options} 
            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                console.log('language changed');
                if (option && setLanguage) {
                    setLanguage('' + option.key as string);
                }

            }}>

        </Dropdown>
    );
};
