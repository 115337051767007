import React from "react";
import { ICMSBlockTypes } from "..";
import { ICMSBlockTypeProps } from "../../ICMSBlock";
import textstyles from './CMSTextBlock.module.scss';

export interface IBlockDataText {
    htmlText: string;
}



const CMSTextBlockReact = (props: ICMSBlockTypeProps) => {
    const textprops = props.properties as IBlockDataText;
    let textvalue = textprops?.htmlText
    if (!textvalue) textvalue = ''
    return (<div className={textstyles.textblock}>
        {props.editMode ?

            <textarea placeholder={'loc textcontent'} key={'textcontent' + props.index}
                onChange={(event) => { props.update({ htmlText: event.target.value }) }}
                value={textvalue}></textarea>
            : <div dangerouslySetInnerHTML={{ __html: textvalue }}></div>
        }
    </div>
    );;


}

export const CMSTextBlock: ICMSBlockTypes = {
    name: 'Text',
    icon: 'FabricTextHighlight',
    id: 1,
    default: {
        htmlText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus lectus velit, venenatis vel purus eget, rhoncus consequat neque. Quisque eget nisi vitae neque placerat pellentesque. Sed a elementum elit, sed venenatis lorem. Donec sit amet massa nunc. Pellentesque suscipit luctus mauris, sed semper enim finibus vitae. Donec tempor rhoncus ante, quis dictum leo. Ut id est fermentum, blandit magna id, eleifend metus.'},
    renderer: CMSTextBlockReact,

    }