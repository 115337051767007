
import { globalConfig } from "./globalconf";


enum WebPushAction {
    subscribe = 'subscribe',
    unsubscribe = 'unsubscribe',
}

interface IRegisteredSubscription {
    sensorId?: string;
}

export let registeredSubscription:IRegisteredSubscription = {}

export const WebPush = {

    convertedVapidKey: function (): Uint8Array {
        return this.urlBase64ToUint8Array(globalConfig.webPush.VAPID_PUBLIC_KEY);
    },
    urlBase64ToUint8Array: function urlBase64ToUint8Array(base64String: string): Uint8Array {
        const padding = "=".repeat((4 - base64String.length % 4) % 4)
        // eslint-disable-next-line
        const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

        const rawData = window.atob(base64)
        const outputArray = new Uint8Array(rawData.length)

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i)
        }
        return outputArray
    },

    sendSubscription: async function (action: WebPushAction, subscription: any, additionaldata?: object): Promise<boolean> {
        let body = {
            action: action.toString(),
            subscription: subscription,
        }

        const response = await fetch(`${globalConfig.webPush.API_URL}/webpush`, {
            method: 'POST',
            body: JSON.stringify({ ...body, ...additionaldata }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if(response.status === 200){
            //const data=response.json();
            return true;
        }
        return false;

    },
    unsubscribeSensor: async function (sensorId?: string): Promise<boolean> {
        const that = this;
        debugger;
        const registration = await navigator.serviceWorker.ready;
        if (!registration.pushManager) {
            return false;
        }
        const sub: PushSubscription | null = await registration.pushManager.getSubscription();
        if (sub && sub.options) {
            const postresult= await that.sendSubscription(WebPushAction.unsubscribe, sub, sensorId ? { sensorid: sensorId } : undefined);
            if(postresult){
                registeredSubscription.sensorId=undefined;
                sub.unsubscribe();
            }
            
        }
        return false;
    },
    subscribeSensor: async function (sensorId: string): Promise<boolean> {
        const that = this;
        if ('serviceWorker' in navigator) {
            try {
                const registration = await navigator.serviceWorker.ready;
                if (!registration.pushManager) {
                    console.log('Push manager unavailable.')
                    return false;
                }

                const existedSubscription = await registration.pushManager.getSubscription();
                if (existedSubscription === null) {
                    console.log('No subscription detected, make a request.')
                    try {
                        const newSubscription: PushSubscription = await registration.pushManager.subscribe({
                            applicationServerKey: that.convertedVapidKey(),
                            userVisibleOnly: true,
                        });
                        console.log('New subscription added.');
                        const postresult=await that.sendSubscription(WebPushAction.subscribe, newSubscription, { sensorid: sensorId });
                        if(postresult){
                            registeredSubscription.sensorId=sensorId;
                        }
                        
                        return postresult;

                    } catch (err) {
                        if (Notification.permission !== 'granted') {
                            console.log('Permission was not granted.')
                        } else {
                            console.error('An error ocurred during the subscription process.', err)
                        }
                        return false;
                    }

                } else {
                    console.log('Existed subscription detected.')
                    const postresult= that.sendSubscription(WebPushAction.subscribe, existedSubscription, { sensorid: sensorId });
                    if(postresult){
                        registeredSubscription.sensorId=sensorId;
                    }
                    
                    //Resend done
                    return postresult;
                }
            } catch (err) {

                console.error('An error ocurred during Service Worker registration.', err)
                return false;
            }
        }
        return false;
    },
    isWebPushAvailable: async function (): Promise<boolean> {
        const registration = await navigator.serviceWorker.ready;
        if (registration) {
            return true;
        }
        return false;
    },
    isWebPushGranted: function (): boolean {

        if (window.Notification.permission !== "granted") {
            return true;
        }
        return false;
    },

    isWebPushRegisterd: async function isWebPushRegisterd(): Promise<boolean> {
        const registration = await navigator.serviceWorker.ready;
        if (!registration.pushManager) {
            return false;
        }
        const sub: PushSubscription | null = await registration.pushManager.getSubscription();
        if (sub) {
            return true;
        }
        return false;
    },
    isThisWebPushRegisterd: async function isThisWebPushRegisterd(sensorId: string): Promise<boolean> {
        const registration = await navigator.serviceWorker.ready;
        if (!registration.pushManager) {
            return false;
        }
        const sub: PushSubscription | null = await registration.pushManager.getSubscription();
        if (sub && registeredSubscription && registeredSubscription.sensorId && registeredSubscription.sensorId === sensorId) {
            //TODO Check SensorId
            return true;
        }
        return false;
    },

    askPermission: async function ():Promise<boolean> {
        return new Promise(function(resolve, reject) {
          const permissionResult = Notification.requestPermission(function(result) {
            resolve(result);
          });
      
          if (permissionResult) {
            permissionResult.then(resolve, reject);
          }
        })
        .then(function(permissionResult) {
          if (permissionResult !== 'granted') {
              return false;
            //throw new Error('We weren\'t granted permission.');
          }
          return true;
        });
      }


}








