export const lightTheme = {
    palette: {
      themePrimary: '#d40000',
      themeLighterAlt: '#fdf3f3',
      themeLighter: '#f8d0d0',
      themeLight: '#f2a9a9',
      themeTertiary: '#e55c5c',
      themeSecondary: '#d91a1a',
      themeDarkAlt: '#be0000',
      themeDark: '#b10000',
      themeDarker: '#770000',
      neutralLighterAlt: '#d8d8d8',
      neutralLighter: '#d4d4d4',
      neutralLight: '#cccccc',
      neutralQuaternaryAlt: '#bebebe',
      neutralQuaternary: '#b5b5b5',
      neutralTertiaryAlt: '#aeaeae',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      black: '#000000',
      white: '#fafafa',
    }};

    export   const darkTheme = {
        palette: {
          themePrimary: '#a10000',
          themeLighterAlt: '#fdf3f3',
          themeLighter: '#f8d0d0',
          themeLight: '#f2a9a9',
          themeTertiary: '#e55c5c',
          themeSecondary: '#d91a1a',
          themeDarkAlt: '#be0000',
          themeDark: '#990000',
          themeDarker: '#770000',
          neutralLighterAlt: '#000000',
          neutralLighter: '#000000',
          neutralLight: '#000000',
          neutralQuaternaryAlt: '#000000',
          neutralQuaternary: '#000000',
          neutralTertiaryAlt: '#000000',
          neutralTertiary: '#c8c8c8',
          neutralSecondary: '#d0d0d0',
          neutralPrimaryAlt: '#dadada',
          neutralPrimary: '#ffffff',
          neutralDark: '#f4f4f4',
          black: '#f8f8f8',
          white: '#000000',
        }};