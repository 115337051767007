import { Callout, DefaultButton, DelayedRender, ProgressIndicator, Spinner, SpinnerSize, Text } from '@fluentui/react';
import * as React from 'react';
import { globalConfig } from '../../globalconf';
import { strings } from '../../localization/localization';
import { RegisterSubscription } from '../RegisterSubscription';
import { DeviceDecoders, DeviceDecoderType, UnkownDecoder } from './DeviceDecoders';
import styles from './DeviceStatus.module.scss';
import { defaultColorSchema, IDeviceData, IDeviceDataResponse, IDeviceDecoder, ITelemetry, TelemetryType } from './DeviceStatus.type';
import { TelemetryTypeDefinitions } from './TelemetryTypeDefinitions';



export interface IDeviceStatusProps {
    deviceId: string;
    accessKey: string;
    refreshMinutes: number;
    darkMode?: boolean
}

export interface IDeviceStatusState {
    refreshTimer: number;
    devicedata?: IDeviceData;
    deviceDecoder: DeviceDecoderType;
    deviceDecoderData: IDeviceDecoder;
    loading: boolean;
    agosec: number;
    error: boolean;
    errorMessage?: string;
    isCalloutVisible: boolean;
}

const intervalDelay = 200;
const intervalIncrement = 0.003;
const agotimeInterval = 1000;

export default class DeviceStatus extends React.Component<IDeviceStatusProps, IDeviceStatusState> {
    private mounted = false;
    private timer: number = -1;
    private agotimer: number = -1;
    private _infoIcon: HTMLElement | null = null;

    constructor(props: IDeviceStatusProps) {
        super(props);

        this.state = {
            refreshTimer: 0,
            loading: true,
            agosec: 0,
            error: false,
            isCalloutVisible: false,
            deviceDecoder: 'unknown',
            deviceDecoderData: UnkownDecoder

        };
        this.toggleIsCalloutVisible = this.toggleIsCalloutVisible.bind(this);
        this.showCalloutVisible = this.showCalloutVisible.bind(this);
        this.hideCalloutVisible = this.hideCalloutVisible.bind(this);
    }

    public async componentDidMount() {
        this.mounted = true;
        this.loadData();
        window.addEventListener('focus', event => {
            if (this.mounted) {
                this.loadData();
            }
        });
        window.addEventListener('onblur', event => { this.stopAllTimer() });
    }

    public componentDidUpdate(lastprops: IDeviceStatusProps) {
        if (this.mounted && this.state.devicedata) {
            if (this.props.darkMode !== lastprops.darkMode) {
                const newcolor = this.getTelemetryColor(this.state.devicedata.telemetry, this.state.deviceDecoderData.mainType);
                this.state.devicedata.color = newcolor;
            }
        }
    }


    public componentWillUnmount() {
        this.mounted = false;
        this.stopAllTimer();
    }

    public render(): React.ReactElement<IDeviceStatusProps> {

        const { deviceId: sensorId, darkMode } = this.props;
        const { devicedata, deviceDecoderData, agosec, error, errorMessage, isCalloutVisible } = this.state;
        if (this.state.loading && !devicedata) {
            //Init Loading
            return (<div>
                <Spinner size={SpinnerSize.large} />
            </div>);
        }
        if (error) {
            return (<div>
                <span>{errorMessage}</span>
                <DefaultButton text={strings.retry} onClick={() => {
                    this.loadData();
                }} />
            </div>);
        }
        if (!devicedata) {

            return (<div>{strings.deviceNoData}</div>);
        }

        let timerago = (<div></div>);
        if (agosec > 0) {
            timerago = (<div className={styles.lastUpdate}>
                {agosec > 24 * 60 * 60 && (<span>{`${Math.floor(agosec / (24 * 60 * 60) % 60)} D `}</span>)}
                {agosec > 60 * 60 && (<span>{`${Math.floor(agosec / 3600 % 60)} h `}</span>)}
                {agosec > 60 && (<span>{`${Math.floor(agosec / 60 % 60)} min `}</span>)}
                <span> {agosec % 60} sec</span>
            </div>);
        }

        // const date = new Date(data.date)
        return (
            <div className={styles.deviceInfo} style={
                { backgroundColor: darkMode ? devicedata.color?.replace('light', '') : devicedata.color }
            }>
                <div className={styles.headline}>

                    {this.getIcon()}

                    <span className={styles.text}>{this.getHeadline()}</span>
                    {globalConfig.features.enablewebpush && (<div>
                        <RegisterSubscription sensorId={this.props.deviceId} />
                    </div>
                    )}
                </div>
                <div className={styles.body}>

                    <div className={styles.sensore}>
                        <span>{devicedata.description ? devicedata.description : sensorId}</span>

                        {devicedata && devicedata.description && (<i className={'ms-Icon ms-Icon--Info'}
                            ref={(infoIcon) => { this._infoIcon = infoIcon; }}
                            onMouseOver={this.showCalloutVisible}
                            onMouseOut={this.hideCalloutVisible}
                            onClick={this.toggleIsCalloutVisible}></i>)}
                        {isCalloutVisible && (
                            <Callout
                                className={styles.callout}
                                target={this._infoIcon}
                                onDismiss={this.hideCalloutVisible}
                                role="status"
                                aria-live="assertive"
                            >
                                <DelayedRender>
                                    <Text className={styles.callouttext}>
                                        {devicedata.description}
                                    </Text>


                                </DelayedRender>
                            </Callout>)}
                    </div>

                    <div className={styles.values}>
                        {deviceDecoderData.renderer(this.props, devicedata)
                        }
                    </div>


                </div>

                <div className={styles.footer}>
                    <div className={styles.footerinforow}>
                        <div className={styles.footerinfoleft}></div>
                        <div className={styles.footerinfocenter}>
                            <div>{timerago}</div>
                            <div className={styles.lastUpdate}>

                                {new Date(devicedata.date).toLocaleString()}
                            </div>
                        </div>
                        <div className={styles.footerinforight}>
                            <a href={`/api/GenerateQRPng?sensorId=${sensorId}&sensorcode=${this.props.accessKey}`} target={'_blank'} >
                                <img src={`/api/GenerateQRPng?sensorId=${sensorId}&sensorcode=${this.props.accessKey}`} />
                            </a>
                        </div>
                    </div>
                    <ProgressIndicator
                        percentComplete={this.state.refreshTimer} />
                </div>
            </div>
        );
    }

    private getIcon(): JSX.Element {
        const { deviceDecoderData } = this.state;
        const { darkMode } = this.props;
        if (deviceDecoderData.iconpart) {
            return (<img src={!darkMode ?
                `/DeviceImages/${deviceDecoderData.iconpart}.svg` :
                `/DeviceImages/${deviceDecoderData.iconpart}_white.svg`
            }
                alt='locSensorIcondark' />);
        }
        return (<></>)
    }
    //currentlang = strings.getLanguage()
    private getHeadline(): string {
        const { deviceDecoderData } = this.state;
        const currentlang: string = strings.getLanguage();
        if (deviceDecoderData.label) {
            const locallabel = (deviceDecoderData.label as any)[currentlang];
            return locallabel ? locallabel : this.state.devicedata?.deviceCode;

        }
        return this.state.devicedata?.deviceDecoder || '';
    }
    private stopAllTimer(): void {
        if (this.timer) {
            clearInterval(this.timer);
        }
        if (this.agotimer) {
            clearInterval(this.agotimer);
        }
    }
    private toggleIsCalloutVisible() {
        this.setState({ isCalloutVisible: !this.state.isCalloutVisible });

    }
    private showCalloutVisible() {
        if (this.state.isCalloutVisible !== true) {
            this.setState({ isCalloutVisible: true });
        }
    }
    private hideCalloutVisible() {
        if (this.state.isCalloutVisible) {
            this.setState({ isCalloutVisible: false });
        }
    }

    private async loadData() {
        try {
            const initdata: IDeviceData = await this.getDeviceData();

            if (this.mounted) {
                let decoderData = this.state.deviceDecoderData;
                if (initdata.deviceDecoder !== this.state.deviceDecoder) {
                    decoderData = DeviceDecoders[initdata.deviceDecoder] || UnkownDecoder
                }
                initdata.color = this.getTelemetryColor(initdata.telemetry, decoderData.mainType);
                this.setState({
                    devicedata: initdata,
                    deviceDecoder: initdata.deviceDecoder,
                    deviceDecoderData: decoderData,
                    loading: false,
                    refreshTimer: 0
                }, this.startTimer);
            }
        } catch (err) {
            if (this.mounted) {
                this.setState({
                    loading: false, error: true,
                    errorMessage: 'No connection',
                    deviceDecoder: 'unknown',
                    deviceDecoderData: UnkownDecoder
                });
            }
        }
    }
    private getTelemetryColor(telemetris: ITelemetry[], telemetryType: TelemetryType): string | undefined {
        const telemetryTypeDefinition = TelemetryTypeDefinitions.filter(t => t.telemetryType === telemetryType);

        if (telemetris && telemetris.length > 0 && telemetryTypeDefinition && telemetryTypeDefinition.length > 0 && telemetryTypeDefinition[0].lvlColorSchema) {
            const td=telemetryTypeDefinition[0].lvlColorSchema;
            const filtered = telemetris.filter(r => r.telemetryType === telemetryType && !!r.telemetryColor && r.telemetryColor.length > 0);
            if (filtered.length > 0) {
                const analyse = filtered[0];
                if (analyse.telemetryThreshold1 && analyse.telemetryThreshold2 && analyse.telemetryMaximum) {
                    if (analyse.telemetryValue < analyse.telemetryThreshold1) {
                        return this.props.darkMode ? td.lvl1.dark : td.lvl1.light;
                    } else if (analyse.telemetryValue < analyse.telemetryThreshold2) {
                        return this.props.darkMode ? td.lvl2.dark : td.lvl2.light;
                    }
                    else {
                        return this.props.darkMode ? td.lvl3.dark : td.lvl3.light;
                    }
                }

                return filtered[0].telemetryColor;
            } else {
                const anycolor = telemetris.filter(r => !!r.telemetryColor && r.telemetryColor.length > 0);
                if (anycolor.length > 0) {
                    return anycolor[0].telemetryColor;
                }
            }
        }
        return undefined;
    }

    private getDeviceData(): Promise<IDeviceDataResponse> {

        const { accessKey, deviceId: sensorId } = this.props;

        return fetch(`https://iot.cubido.eu/go?id=${sensorId}&c=${accessKey}&f=json`)
            .then(d => {
                if (d.status === 200) {
                    return d.json()
                }
                throw new Error('no connection');
            })
            .then(
                result => {
                    if (!result.deviceDecoder) {
                        result.deviceDecoder = 'unknown';
                    }
                    return result as IDeviceDataResponse;
                }
            );
    }
    private startTimer() {
        if (this.timer < 0) {
            this.timer = setInterval(() => {
                const nexttick = (intervalIncrement / this.props.refreshMinutes + this.state.refreshTimer) % 1;

                if (nexttick <= intervalIncrement) {
                    this.loadData();
                }
                this.setState({ refreshTimer: nexttick });

            }, intervalDelay) as any;
        }
        if (this.agotimer < 0 && this.state.devicedata && this.state.devicedata.date) {
            this.agotimer = setInterval(() => {
                if (this.state.devicedata && this.state.devicedata.date) {
                    const datedata = new Date(this.state.devicedata.date);
                    const datenow = new Date();
                    const delta = datenow.getTime() - datedata.getTime();
                    this.setState({ agosec: Math.floor(delta / 1000) });
                } else {
                    if (this.state.agosec > 0)
                        this.setState({ agosec: 0 });
                }


            }, agotimeInterval) as any;
        }
    }

}
