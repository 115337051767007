
export interface IMessage {
    messageType: MessageType;
    title: string;
    details: string;
}

export enum MessageType {
    Alert = 1,
    Info = 2,
    Waring = 4
}


